import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  CustomReportInterface,
  LocationInvoiceReport,
  CustomerExport,
  SailorReport,
  InvoicingReportColumns,
  PendingReport,
  MovementManagementReport,
  MovementPlanReport,
  ProductMovementReport,
  BoatReport,
  ProductsReport,
  DetailBoatsReport,
  InvoiceTaxProduc,
  ProductMovementReportNfe,
  SuperInvoicingReportColumns,
  SlingReportColumns,
  InvoiceReportColumns,
  StockMovementReport,
  WashEngineTurnColumns,
  NavigationPlanReportColumns,
  VisitorsColumns,
  PropertiesColumns,
  OrdersColumns,
  ServiceHistoricReport,
  ServiceHistoricReportNfe,
  FranchiseControllerReport,
  CustomerCreditExport,
  DetailCustomerReport,
  ReadjustmentExport,
  PendingCustomerReport,
  ReportAccessControler,
} from 'src/app/models/custom-exportable';
import { LocationInvoice } from 'src/app/models/location-invoice';
import { CustomReportService } from 'src/app/services/custom-report.service';
import { ToastService } from 'src/app/services/toast.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import * as XLSX from 'xlsx';
import { WorkSheet } from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Marina } from '../../models/marina';
import { MessageUtil } from '../../utils/message.util';
import { FinancesService } from 'src/app/services/finances.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-custom-report-export',
  templateUrl: './custom-report-export.component.html',
  styleUrls: ['./custom-report-export.component.scss'],
})
export class CustomReportExportComponent implements OnInit {
  formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  //Usados pelo relatório de produtos
  listaPlanoContasFlat: any[] = [];
  listaCentroCustoFlat: any[] = [];

  selectExportType: RadioButtonModule = 'XLS';
  customersTable: LocationInvoice[] = [];
  pendingTable: CustomReportInterface[] = [];
  typeReport: string;
  sourceList: CustomReportInterface[] = [];
  targetList: CustomReportInterface[] = [];
  exportData: any[] = [];
  cols = [];
  objModel = {};
  patternReport: boolean = true;

  //NOVOS TOTAIS
  totals: { [key: string]: number } = {};

  constructor(
    public config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public customReportService: CustomReportService,
    private toastService: ToastService,
    private financesService: FinancesService,
    private messageUtil: MessageUtil
  ) {}

  async ngOnInit() {
    this.sourceList = [];
    this.customersTable = [];
    this.customersTable = this.config.data.table;
    this.typeReport = this.config.data.type;
    switch (this.typeReport) {
      case 'CUSTOMER':
        this.sourceList = [...CustomerExport];
        break;
      case 'SAILOR':
        this.sourceList = [...SailorReport];
        break;
      case 'BOAT':
        this.sourceList = [...BoatReport];
        break;
      case 'LOCATION_INVOICE':
        this.sourceList = [...LocationInvoiceReport];
        break;
      case 'INVOICING':
        this.sourceList = [...InvoicingReportColumns];
        break;
      case 'PENDING':
        this.sourceList = [...PendingReport];
        break;
      case 'MOVEMENT_MANAGEMENT':
        this.sourceList = [...MovementManagementReport];
        break;
      case 'MOVEMENT_PLAN':
        this.sourceList = [...MovementPlanReport];
        break;
      case 'PRODUCT_MOVEMENT':
        this.sourceList = ProductMovementReport;
        break;
      case 'PRODUCT_MOVEMENT_NFE':
        this.sourceList = ProductMovementReportNfe;
        break;
      case 'STOCK_MOVEMENT':
        this.sourceList = [...StockMovementReport];
        break;
      case 'INVOICE':
        this.sourceList = [...InvoiceReportColumns];
        break;
      case 'PRODUCT':
        this.sourceList = ProductsReport;
        this.listaPlanoContasFlat = await this.getPlanoContasReceita();
        this.listaCentroCustoFlat = await this.getCentroCusto();
        break;

      case 'DETAIL_BOATS':
        this.sourceList = [...DetailBoatsReport];
        break;
      case 'DETAIL_CUSTOMER':
        this.sourceList = [...DetailCustomerReport];
        break;
      case 'TAX_PRODUCT':
        this.sourceList = InvoiceTaxProduc;
        break;
      case 'SUPER_INVOICING':
        this.sourceList = [...SuperInvoicingReportColumns];
        break;
      case 'SLING':
        this.sourceList = [...SlingReportColumns];
        break;
      case 'WASH_ENGINE_TURN':
        this.sourceList = [...WashEngineTurnColumns];
        break;
      case 'NAVIGATION_PLAN':
        this.sourceList = [...NavigationPlanReportColumns];
        break;
      case 'VISITORS':
        this.sourceList = [...VisitorsColumns];
        break;
      case 'PROPERTIES':
        this.sourceList = [...PropertiesColumns];
        break;
      case 'ORDER':
        this.sourceList = [...OrdersColumns];
        break;
      case 'SERVICE_HISTORIC':
        this.sourceList = ServiceHistoricReport;
        break;
      case 'SERVICE_HISTORIC_NFE':
        this.sourceList = ServiceHistoricReportNfe;
        break;
      case 'FRANCHISE_CONTROLLER':
        this.sourceList = FranchiseControllerReport;
        break;
      case 'CUSTOMER_CREDIT':
        this.sourceList = [...CustomerCreditExport];
        break;
      case 'READJUSTMENT_REPORT':
        this.sourceList = [...ReadjustmentExport];
        break;
      case 'PENDING_CUSTOMER':
        this.sourceList = [...PendingCustomerReport];
        break;
      case 'REPORT_ACCESS_CONTROLER':
        this.sourceList = [...ReportAccessControler];
        break;
    }
    this.customReportService
      .getAllByReportType(this.typeReport)
      .subscribe((targetList) => this.formatTargetAndSourceList(targetList));
  }

  validateTargetList(): boolean {
    if (this.targetList.length === 0) {
      this.toastService.warning(
        'Alguma coluna precisa estar na lista de exportação!'
      );
      return false;
    }
    if (this.selectExportType === 'PDF' && this.targetList.length > 9) {
      this.toastService.warning('Apenas 9 colunas na exportação em pdf!');
      return false;
    }
    return true;
  }

  exportTable(): void {
    if (!this.validateTargetList()) {
      return;
    }
    if (this.patternReport) {
      this.saveTargetList();
    }
    this.createObjectWithColumnsSelected();
    this.manipulateExportableData();
    this.exportData.map((item) => {
      if (item.totalValue) {
        item.totalValue = item.totalValue.replace(/\./g, ',');
      }
      return item;
    });
    if (this.selectExportType === 'XLS' || this.selectExportType === 'CSV') {
      this.exportCSVOrXLS();
    } else {
      this.exportPDF();
    }
    this.toastService.success('Relatorio criado com sucesso!');
    this.dialogRef.close();
  }

  async exportPDF(): Promise<void> {
    const marina: Marina = StorageUtil.getMarina();
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      floatPrecision: 2,
    });
    doc.setFontSize(18);
    doc.setFontSize(11);
    //SETANDO EIXO HORIZONTAL DOS ITENS DO CABEÇALHO
    const horizontalCenter = doc.internal.pageSize.getWidth() / 2;
    //LOGO
    const logoHorizontalLenghtAdjustament = 25;
    const logoX = horizontalCenter - logoHorizontalLenghtAdjustament;
    //TRADENAME
    const pixelsPerLetter = 6.5;
    const tradeNameHorizontalLenghtAdjustament =
      (marina.tradeName.length / 2) * pixelsPerLetter;
    const marinaNameX = horizontalCenter - tradeNameHorizontalLenghtAdjustament;
    let logoCompany64 = null;
    if (marina.logoFile !== null) {
      logoCompany64 = await this.getBase64Image(marina.logoFile);
    }
    const logoEasyMarine64 = await this.getBase64Image(
      '../../../assets/images/EASY_MARINE_MARCA_OFICIAL.png'
    );
    const totalRecords = this.exportData.length;
    const dateNow = new Date().toLocaleString('pt-br');
    //TÍTULOS
    let titulo = '';
    let tituloX = 445;
    if (this.typeReport === 'PENDING') {
      titulo = 'Resumo das Pendências';
      tituloX = 400;
    } else if (this.typeReport === 'PENDING-CUSTOMER') {
      titulo = 'Resumo das Pendências do Cliente';
      tituloX = 435;
    } else if (this.typeReport === 'CUSTOMER') {
      titulo = 'Relatório de Clientes';
      tituloX = 385;
    } else if (this.typeReport === 'SAILOR') {
      titulo = 'Relatório de Marinheiros';
      tituloX = 400;
    } else if (this.typeReport === 'INVOICING') {
      titulo = 'Relatório de Faturamento';
      tituloX = 400;
    } else if (this.typeReport === 'INVOICE') {
      titulo = 'Relatório de Faturas';
      tituloX = 385;
    } else if (this.typeReport === 'MOVEMENT_PLAN') {
      titulo = 'Relatório de Planos de Movimentações';
      tituloX = 442;
    } else if (this.typeReport === 'PRODUCT_MOVEMENT') {
      titulo = 'Relatório de Histórico de Produtos';
      tituloX = 435;
    } else if (this.typeReport === 'LOCATION_INVOICE') {
      titulo = 'Resumo mensal de grupo de vagas';
      tituloX = 435;
    } else if (this.typeReport === 'LOCATION_INVOICE') {
      titulo = 'Resumo dos Produtos e Serviços';
      tituloX = 434;
    } else if (this.typeReport === 'SLING') {
      titulo = 'Relatório de Lingadas';
      tituloX = 400;
    } else if (
      this.typeReport === 'SERVICE_HISTORIC_NFE' ||
      this.typeReport === 'SERVICE_HISTORIC'
    ) {
      titulo = 'Relatório de Histórico de Produtos';
      tituloX = 435;
    } else if (this.typeReport === 'READJUSTMENT_REPORT') {
      titulo = 'Relatório de Reajustes';
      tituloX = 395;
    }
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: [this.cols],
      body: this.exportData,
      bodyStyles: { halign: 'center' },
      headStyles: { halign: 'center', fillColor: '#495057' },
      theme: 'striped',
      didDrawPage: function (data) {
        // PageSizes
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        // Header
        doc.setFontSize(16);
        doc.setTextColor(40);
        if (logoCompany64 !== null) {
          doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
        }
        doc.text(marina.tradeName, marinaNameX, 65);
        doc.setFontSize(10);
        doc.text(dateNow, pageWidth - 100, 85);
        doc.setFontSize(20);
        doc.setTextColor('#495057');
        doc.text(titulo, pageWidth - tituloX, 85);
        // Footer
        doc.setFontSize(10);
        const numOfPages = doc.internal.pages.length - 1;
        doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
        doc.setFontSize(10);
        doc.text(
          `Registros: ${totalRecords}`,
          data.settings.margin.left,
          pageHeight - 32
        );
        doc.addImage(
          logoEasyMarine64,
          'PNG',
          data.settings.margin.left,
          pageHeight - 24,
          20,
          20
        );
        doc.text(
          'Desenvolvido por Easymarine',
          data.settings.margin.left + 23,
          pageHeight - 12
        );
      },
      margin: { top: 90 },
    });

    //PÁGINAS DE TOTAIS
    if (this.typeReport === 'INVOICING') {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor('#000000');

      let posX = 130;
      doc.text('Total de Registros: ' + this.exportData.length, 400, posX);
      posX += 20;
      doc.text(
        'Total de Notas: ' +
          this.formatter.format(this.totals['invoicingTotalNfsValue'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Recibos: ' +
          this.formatter.format(this.totals['invoicingTotalReceiptValue'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total Geral: ' +
          this.formatter.format(this.totals['invoicingTotalInvoiceValue'] || 0),
        400,
        posX
      );
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 90, doc.internal.pageSize.width - 20, 90);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setPage(doc.internal.pages.length);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(16);
      doc.setTextColor(40);
      if (logoCompany64 != null) {
        doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
      }
      doc.text(marina.tradeName, marinaNameX, 65);
      doc.setFontSize(10);
      doc.text(dateNow, pageWidth - 100, 85);
      doc.setFontSize(20);
      doc.setTextColor('#495057');
      doc.text(titulo, pageWidth - tituloX, 85);
      // Footer
      doc.setFontSize(10);
      const numOfPages = doc.internal.pages.length - 1;
      doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
      doc.setFontSize(10);
      doc.addImage(logoEasyMarine64, 'PNG', 30, pageHeight - 24, 20, 20);
      doc.text('Desenvolvido por Easymarine', 53, pageHeight - 12);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
    }

    if (this.typeReport === 'PENDING') {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor('#000000');

      let posX = 130;
      doc.text('Total de Registros: ' + this.exportData.length, 400, posX);
      posX += 20;
      doc.text(
        'Total de Pendências: ' +
          this.formatter.format(this.totals['pendingTotalTotalCustomer'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total com Juros: ' +
          this.formatter.format(this.totals['pendingTotalFullValue'] || 0),
        400,
        posX
      );
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 90, doc.internal.pageSize.width - 20, 90);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setPage(doc.internal.pages.length);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(16);
      doc.setTextColor(40);
      if (logoCompany64 != null) {
        doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
      }
      doc.text(marina.tradeName, marinaNameX, 65);
      doc.setFontSize(10);
      doc.text(dateNow, pageWidth - 100, 85);
      doc.setFontSize(20);
      doc.setTextColor('#495057');
      doc.text(titulo, pageWidth - tituloX, 85);
      // Footer
      doc.setFontSize(10);
      const numOfPages = doc.internal.pages.length - 1;
      doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
      doc.setFontSize(10);
      doc.addImage(logoEasyMarine64, 'PNG', 30, pageHeight - 24, 20, 20);
      doc.text('Desenvolvido por Easymarine', 53, pageHeight - 12);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
    }

    if (this.typeReport === 'INVOICE') {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor('#000000');

      let posX = 130;
      doc.text('Total de Registros: ' + this.exportData.length, 400, posX);
      posX += 20;
      doc.text(
        'Total de Faturas: ' +
          this.formatter.format(this.totals['invoiceTotalValue'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Desconto: ' +
          this.formatter.format(this.totals['invoiceTotalDiscount'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Juros: ' +
          this.formatter.format(this.totals['invoiceTotalInterest'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Valor Pendente: ' +
          this.formatter.format(this.totals['invoiceTotalPendingValue'] || 0),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Valor Pago: ' +
          this.formatter.format(this.totals['invoiceTotalTotalPaid'] || 0),
        400,
        posX
      );
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 90, doc.internal.pageSize.width - 20, 90);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setPage(doc.internal.pages.length);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(16);
      doc.setTextColor(40);
      if (logoCompany64 != null) {
        doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
      }
      doc.text(marina.tradeName, marinaNameX, 65);
      doc.setFontSize(10);
      doc.text(dateNow, pageWidth - 100, 85);
      doc.setFontSize(20);
      doc.setTextColor('#495057');
      doc.text(titulo, pageWidth - tituloX, 85);
      // Footer
      doc.setFontSize(10);
      const numOfPages = doc.internal.pages.length - 1;
      doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
      doc.setFontSize(10);
      doc.addImage(logoEasyMarine64, 'PNG', 30, pageHeight - 24, 20, 20);
      doc.text('Desenvolvido por Easymarine', 53, pageHeight - 12);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
    }

    if (this.typeReport === 'DETAIL_BOATS') {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor('#000000');
      let posX = 130;
      doc.text('Totais Mensais Projetados', 50, posX);
      doc.text('Totais Anuais Projetados', 400, posX);
      posX += 40;
      doc.text(
        'Total de Serviços: ' +
          this.formatter.format(
            this.totals['detailBoatsTotalBoatServicesValue'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Serviços Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailBoatsTotalBoatServicesValue'] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Desconto: ' +
          this.formatter.format(
            this.totals['detailBoatsTotalBoatServicesDiscount'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Descontos Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailBoatsTotalBoatServicesDiscount'] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Desconto Vencimento: ' +
          this.formatter.format(
            this.totals['detailBoatsTotalBoatServicesDueDateDiscount'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Desconto Vencimento Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailBoatsTotalBoatServicesDueDateDiscount'] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total Geral: ' +
          this.formatter.format(
            this.totals['detailBoatsTotalBoatServicesTotalValue'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Total Geral Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailBoatsTotalBoatServicesTotalValue'] || 0
            )
          ),
        400,
        posX
      );
      posX += 40;
      doc.text('Outras Estatísticas', 50, posX);
      posX += 20;
      doc.text('Total Embarcações: ' + this.customersTable.length, 50, posX);
      doc.text(
        'Total pés: ' + this.totals['detailBoatsTotalCommercialLength'],
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Pés por Embarcações: ' +
          (
            this.totals['detailBoatsTotalCommercialLength'] /
            this.customersTable.length
          ).toFixed(2),
        50,
        posX
      );
      doc.text(
        'Total por Embarcação: ' +
          this.formatter.format(
            this.totals['detailBoatsTotalBoatServicesTotalValue'] /
              this.customersTable.length
          ),
        400,
        posX
      );

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 90, doc.internal.pageSize.width - 20, 90);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setPage(doc.internal.pages.length);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(16);
      doc.setTextColor(40);
      doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
      doc.text(marina.tradeName, marinaNameX, 65);
      doc.setFontSize(10);
      doc.text(dateNow, pageWidth - 100, 85);
      doc.setFontSize(20);
      doc.setTextColor('#495057');
      doc.text(titulo, pageWidth - tituloX, 85);
      // Footer
      doc.setFontSize(10);
      const numOfPages = doc.internal.pages.length - 1;
      doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
      doc.setFontSize(10);

      doc.addImage(logoEasyMarine64, 'PNG', 30, pageHeight - 24, 20, 20);
      doc.text('Desenvolvido por Easymarine', 53, pageHeight - 12);

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
    }

    if (this.typeReport === 'DETAIL_CUSTOMER') {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor('#000000');
      let posX = 130;
      doc.text('Totais Mensais Projetados', 50, posX);
      doc.text('Totais Anuais Projetados', 400, posX);
      posX += 40;
      doc.text(
        'Total de Serviços: ' +
          this.formatter.format(
            this.totals['detailCustomerTotalCustomerServiceValue'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Serviços Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailCustomerTotalCustomerServiceValue'] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Desconto: ' +
          this.formatter.format(
            this.totals['detailCustomerTotalCustomerServiceDiscount'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Descontos Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailCustomerTotalCustomerServiceDiscount'] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total de Desconto Vencimento: ' +
          this.formatter.format(
            this.totals['detailCustomerTotalCustomerServiceDueDateDiscount'] ||
              0
          ),
        50,
        posX
      );
      doc.text(
        'Desconto Vencimento Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals[
                'detailCustomerTotalCustomerServiceDueDateDiscount'
              ] || 0
            )
          ),
        400,
        posX
      );
      posX += 20;
      doc.text(
        'Total Geral: ' +
          this.formatter.format(
            this.totals['detailCustomerTotalCustomerServiceTotal'] || 0
          ),
        50,
        posX
      );
      doc.text(
        'Total Geral Anual: ' +
          this.formatter.format(
            this.getAnualValue(
              this.totals['detailCustomerTotalCustomerServiceTotal'] || 0
            )
          ),
        400,
        posX
      );

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 90, doc.internal.pageSize.width - 20, 90);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setPage(doc.internal.pages.length);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.setFontSize(16);
      doc.setTextColor(40);
      doc.addImage(logoCompany64, 'PNG', logoX, 5, 50, 50);
      doc.text(marina.tradeName, marinaNameX, 65);
      doc.setFontSize(10);
      doc.text(dateNow, pageWidth - 100, 85);
      doc.setFontSize(20);
      doc.setTextColor('#495057');
      doc.text(titulo, pageWidth - tituloX, 85);
      // Footer
      doc.setFontSize(10);
      const numOfPages = doc.internal.pages.length - 1;
      doc.text(`Pag. ${numOfPages}`, 570, pageHeight - 32);
      doc.setFontSize(10);

      doc.addImage(logoEasyMarine64, 'PNG', 30, pageHeight - 24, 20, 20);
      doc.text('Desenvolvido por Easymarine', 53, pageHeight - 12);

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
    }

    doc.save('newpdf.pdf');
  }

  exportCSVOrXLS(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.exportData);
    this.changeHeaderWorksheet(worksheet);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const date = moment(new Date()).utc(true).format('DD-MM-YYYY');
    const marina = StorageUtil.getMarinaName();
    const reportTypeTranslated =
      this.messageUtil.translateKey(this.typeReport) || this.typeReport;
    if (this.selectExportType === 'XLS') {
      const excelBuffer: any = this.convertReportToXls(workbook);
      this.saveAsExcelOrCsvFile(
        excelBuffer,
        `${reportTypeTranslated}_${date}_${marina}`,
        false
      );
    } else {
      const csvBuffer: any = this.convertReportToCsv(workbook);
      this.saveAsExcelOrCsvFile(
        csvBuffer,
        `${reportTypeTranslated}_${date}_${marina}`,
        true
      );
    }
  }

  formatTargetAndSourceList(targetList): void {
    targetList.map((item) => {
      delete item.id;
      delete item.user;
    });
    this.targetList = targetList;
    this.targetList.forEach((customReport) => {
      this.sourceList = this.sourceList.filter(
        (item) => item.attribute !== customReport.attribute
      );
    });
  }

  saveTargetList(): void {
    this.customReportService.saveCustomReport(this.targetList).subscribe();
  }

  async getPlanoContasReceita(): Promise<any[]> {
    return this.financesService.findPlanoContaReceitaFlat();
  }

  async getCentroCusto(): Promise<any[]> {
    return this.financesService.findCentroCustoFlat();
  }

  getLabelChartAccount(chartAccountId: number): string {
    let label = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.listaPlanoContasFlat.length; ++i) {
      if (this.listaPlanoContasFlat[i].idPlanoConta === chartAccountId) {
        label = this.listaPlanoContasFlat[i].label;
      }
    }
    return label;
  }

  getLabelCostCenter(costCenterId: number): string {
    let label = '';
    for (let i = 0; i < this.listaCentroCustoFlat.length; ++i) {
      if (this.listaCentroCustoFlat[i].idCentroCusto === costCenterId) {
        label = this.listaCentroCustoFlat[i].label;
      }
    }
    return label;
  }

  convertReportToXls(workbook): void {
    return XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  }

  convertReportToCsv(workbook): void {
    return XLSX.write(workbook, {
      bookType: 'csv',
      type: 'array',
    });
  }

  saveAsExcelOrCsvFile(buffer: any, fileName: string, isCSV: boolean): void {
    let FILE_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let FILE_EXTENSION = '.xlsx';
    if (isCSV) {
      FILE_TYPE = 'text/csv;encoding:utf-8';
      FILE_EXTENSION = '.csv';
    }
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + FILE_EXTENSION);
  }

  converte(): string[] {
    const alph = 'ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alph.split('');
  }

  changeHeaderWorksheet(worksheet: WorkSheet): void {
    const alphabet = this.converte();
    for (let i = 0; i < this.cols.length; i++) {
      if (i >= 26) {
        worksheet[`A${alphabet[i]}1`].v = this.cols[i].header;
      } else {
        worksheet[`${alphabet[i]}1`].v = this.cols[i].header;
      }
    }
  }

  createObjectWithColumnsSelected(): void {
    const descriptor = Object.create({
      value: '',
      writable: true,
      enumerable: true,
    });
    this.targetList.forEach((item) => {
      Object.defineProperty(this.objModel, item.attribute, descriptor);
      if (this.selectExportType === 'XLS' || this.selectExportType === 'CSV') {
        this.cols.push({ field: item.attribute, header: item.text });
      } else {
        this.cols.push(item.text);
      }
    });
  }

  manipulateExportableData(): void {
    this.addTotals();

    if (this.selectExportType === 'XLS' || this.selectExportType === 'CSV') {
      this.customersTable.forEach((item) => {
        const newObj = { ...this.objModel };
        Object.keys(newObj).map((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            switch (key) {
              case 'lastDateAttempt':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'financialBlock':
                if (item[key] == true) {
                  newObj[key] = 'Bloqueado';
                } else {
                  newObj[key] = 'Desbloqueado';
                }
                break;

              case 'nameCustomer':
                newObj[key] = item[key].toString();
                break;
              case 'price':
                newObj[key] = item[key]?.value?.toFixed(2);
                break;

              case 'typesSailor':
                let typesSailor: String = '';
                item[key]
                  .toString()
                  .split(',')
                  .forEach((item) => {
                    typesSailor =
                      typesSailor +
                      this.messageUtil.translateKey(
                        'SAILOR-INFO.SAILOR-TYPE.' + item
                      ) +
                      ', ';
                  });
                typesSailor = typesSailor.substring(0, typesSailor.length - 2);
                newObj[key] = typesSailor;
                break;
              case 'objective':
                newObj[key] = this.messageUtil.translateKey(item[key]);
                break;
              case 'movementPlanStatus':
                newObj[key] = this.messageUtil.translateKey(item[key]);
                break;
              case 'origin':
                newObj[key] = this.messageUtil.translateKey(item[key]);
                break;
              case 'type':
                newObj[key] = this.messageUtil.translateKey(item[key]);
                break;
              case 'typeLicense':
                let typeLicense: String = '';
                item[key]
                  .toString()
                  .split(',')
                  .forEach((item) => {
                    typeLicense =
                      typeLicense +
                      this.messageUtil.translateKey(
                        'LICENCE-INFO.LICENCE-TYPE.' + item
                      ) +
                      ', ';
                  });
                typeLicense = typeLicense.substring(0, typeLicense.length - 2);
                newObj[key] = typeLicense;
                break;
              case 'nameBoats':
                newObj[key] = item[key].toString();
                break;
              case 'birthday':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'billingDay':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'expiration':
                newObj[key] = item[key].toString() + 'º dia';
                break;
              case 'licenceExpiration':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'dateOperation':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY HH:mm:ss');
                break;
              // case 'competenceDate':
              //   newObj[key] = moment(item[key]).format('DD/MM/YYYY');
              //   break;
              case 'issueDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'dueDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'createdAt':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'executionDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'returnTime':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'licenceExpiration':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'serviceStartDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'serviceEndDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'lastReadjustmentDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'orderRequestDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY HH:mm');
                break;
              case 'orderUpdateDate':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY HH:mm');
                break;
              case 'totalCustomer':
                newObj[key] = item[key]?.toFixed(2);
                break;
              case 'totalValue':
                newObj[key] = item[key]?.toFixed(2);
                break;
              case 'stockValue':
                newObj[key] = item[key]?.toFixed(2);
                break;
              case 'stock':
                newObj[key] = item[key]?.toFixed(2);
                break;
              case 'itemValue':
                newObj[key] = item[key]?.toFixed(2);
                break;

              //PRODUTOS
              case 'productType':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.toUpperCase()
                );
                break;
              case 'productCategory':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.productCategoryType?.toUpperCase()
                );
                break;
              case 'orderControl':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.toUpperCase()
                );
                break;
              case 'orderOrigin':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.toUpperCase()
                );
                break;

              case 'orderStatus':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.toUpperCase()
                );
                break;
              case 'invoiceStatus':
                newObj[key] = this.messageUtil.translateKey(
                  item[key]?.toUpperCase()
                );
                break;
              case 'active':
                if (newObj[key]) {
                  newObj[key] = 'Ativo';
                } else {
                  newObj[key] = 'Inativo';
                }
                break;
              case 'chartAccountId':
                newObj[key] = this.getLabelChartAccount(item[key]);
                break;
              case 'costCenterId':
                newObj[key] = this.getLabelCostCenter(item[key]);
                break;
              default:
                newObj[key] = item[key];
            }
          } else {
            newObj[key] = '';
          }
        });
        this.exportData.push(newObj);
      });
    } else {
      this.customersTable.forEach((item) => {
        const newObj = [];
        const baseModel = { ...this.objModel };
        Object.keys(baseModel).map((key) => {
          let attr = item[key];
          if (item[key] !== null && item[key] !== undefined) {
            switch (key) {
              case 'itemValue':
                attr = item[key].toFixed(2);
                break;
              case 'receiptValue':
                attr = item[key].toFixed(2);
                break;
              case 'noteValue':
                attr = item[key].toFixed(2);
                break;
              case 'totalValue':
                attr = item[key].toFixed(2);
                break;
              case 'totalCustomer':
                attr = item[key].toFixed(2);
                break;
              case 'financialBlock':
                if (item[key] == true) {
                  attr = 'Bloqueado';
                } else {
                  attr = 'Desbloqueado';
                }
                break;
              case 'typesSailor':
                let typesSailor: String = '';
                item[key]
                  .toString()
                  .split(',')
                  .forEach((item) => {
                    typesSailor =
                      typesSailor +
                      this.messageUtil.translateKey(
                        'SAILOR-INFO.SAILOR-TYPE.' + item
                      ) +
                      ', ';
                  });
                typesSailor = typesSailor.substring(0, typesSailor.length - 2);
                attr = typesSailor;
                break;
              case 'typeLicense':
                let typeLicense: String = '';
                item[key]
                  .toString()
                  .split(',')
                  .forEach((item) => {
                    typeLicense =
                      typeLicense +
                      this.messageUtil.translateKey(
                        'LICENCE-INFO.LICENCE-TYPE.' + item
                      ) +
                      ', ';
                  });
                typeLicense = typeLicense.substring(0, typeLicense.length - 2);
                attr = typeLicense;
                break;
              case 'objective':
              case 'movementPlanStatus':
                attr = this.messageUtil.translateKey(item[key]);
                break;
              case 'competenceDate':
                attr = item[key];
                break;
              case 'dueDate':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'createdAt':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'executionDate':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'returnTime':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'licenceExpiration':
                newObj[key] = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'origin':
                attr = this.messageUtil.translateKey(item[key]?.toUpperCase());
                break;
              case 'type':
                newObj[key] = this.messageUtil.translateKey(item[key]);
                break;
              case 'birthday':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'billingDay':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'expiration':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'licenceExpiration':
                attr = moment(item[key]).format('DD/MM/YYYY');
                break;
              case 'dateOperation':
                attr = moment(item[key]).format('DD/MM/YYYY HH:mm:ss');
                break;
              case 'totalCustomer':
                attr = item[key]?.toFixed(2);
                break;
              case 'stockValue':
                attr = item[key]?.toFixed(2);
                break;
              case 'stock':
                attr = item[key]?.toFixed(2);
                break;
              //PRODUTOS
              case 'productType':
                attr = this.messageUtil.translateKey(item[key]?.toUpperCase());
                break;
              case 'productCategory':
                attr = this.messageUtil.translateKey(
                  item[key]?.productCategoryType?.toUpperCase()
                );
                break;
              case 'orderControl':
                attr = this.messageUtil.translateKey(item[key]?.toUpperCase());
                break;
              case 'active':
                if (attr) {
                  attr = 'Ativo';
                } else {
                  attr = 'Inativo';
                }
                break;
              case 'chartAccountId':
                attr = this.getLabelChartAccount(item[key]);
                break;
              case 'costCenterId':
                attr = this.getLabelCostCenter(item[key]);
                break;
              case 'price':
                attr = item[key]?.value?.toFixed(2);
                break;
              default:
                attr = item[key];
            }
          } else {
            attr = '';
          }
          newObj.push(attr);
        });
        this.exportData.push(newObj);
      });

      //ADICIONAR LINHA DOS TOTAIS
    }
    if (
      (this.typeReport === 'PENDING' ||
        this.typeReport === 'INVOICING' ||
        this.typeReport === 'INVOICE' ||
        this.typeReport === 'DETAIL_BOATS' ||
        this.typeReport === 'DETAIL_CUSTOMER') &&
      (this.selectExportType === 'XLS' || this.selectExportType === 'CSV')
    ) {
      let temp = { ...this.exportData[0] };

      for (let prop in temp) {
        temp[prop] = '';
      }
      this.exportData.push({ ...temp });
      const keys = Object.keys(temp);
      const firstKey = keys[0];
      temp[firstKey] = `Totais`;

      //PENDING
      if ('fullValue' in temp) {
        temp.fullValue = this.formatter.format(
          this.totals['pendingTotalFullValue']
        );
      }
      if ('totalCustomer' in temp) {
        temp.totalCustomer = this.formatter.format(
          this.totals['pendingTotalTotalCustomer']
        );
      }
      if ('nameCustomer' in temp) {
        temp.nameCustomer = this.customersTable.length;
      }

      //INVOICE
      if ('value' in temp) {
        temp.value = this.formatter.format(this.totals['invoiceTotalValue']);
      }
      if ('discount' in temp) {
        temp.discount = this.formatter.format(
          this.totals['invoiceTotalDiscount']
        );
      }
      if ('interest' in temp) {
        temp.interest = this.formatter.format(
          this.totals['invoiceTotalInterest']
        );
      }
      if ('pendingValue' in temp) {
        temp.pendingValue = this.formatter.format(
          this.totals['invoiceTotalPendingValue']
        );
      }
      if ('totalPaid' in temp) {
        temp.totalPaid = this.formatter.format(
          this.totals['invoiceTotalTotalPaid']
        );
      }

      //INVOICING
      if ('nfsValue' in temp) {
        temp.nfsValue = this.formatter.format(
          this.totals['invoicingTotalNfsValue']
        );
      }
      if ('receiptValue' in temp) {
        temp.receiptValue = this.formatter.format(
          this.totals['invoicingTotalReceiptValue']
        );
      }
      if ('invoiceValue' in temp) {
        temp.invoiceValue = this.formatter.format(
          this.totals['invoicingTotalInvoiceValue']
        );
      }

      //DETAIL_BOATS
      if ('commercialLength' in temp) {
        temp.commercialLength = this.totals['detailBoatsTotalCommercialLength'];
      }
      if ('boatServicesValue' in temp) {
        temp.boatServicesValue = this.formatter.format(
          this.totals['detailBoatsTotalBoatServicesValue']
        );
      }
      if ('boatServicesDiscount' in temp) {
        temp.boatServicesDiscount = this.formatter.format(
          this.totals['detailBoatsTotalBoatServicesDiscount']
        );
      }
      if ('boatServicesDueDateDiscount' in temp) {
        temp.boatServicesDueDateDiscount = this.formatter.format(
          this.totals['detailBoatsTotalBoatServicesDueDateDiscount']
        );
      }
      if ('boatServicesTotalValue' in temp) {
        temp.boatServicesTotalValue = this.formatter.format(
          this.totals['detailBoatsTotalBoatServicesTotalValue']
        );
      }

      //DETAIL_BOATS
      if ('customerServiceValue' in temp) {
        temp.customerServiceValue = this.formatter.format(
          this.totals['detailCustomerTotalCustomerServiceValue']
        );
      }
      if ('customerServiceDiscount' in temp) {
        temp.customerServiceDiscount = this.formatter.format(
          this.totals['detailCustomerTotalCustomerServiceDiscount']
        );
      }
      if ('customerServiceDueDateDiscount' in temp) {
        temp.customerServiceDueDateDiscount = this.formatter.format(
          this.totals['detailCustomerTotalCustomerServiceDueDateDiscount']
        );
      }
      if ('customerServiceTotal' in temp) {
        temp.customerServiceTotal = this.formatter.format(
          this.totals['detailCustomerTotalCustomerServiceTotal']
        );
      }

      this.exportData.push(temp);
    }
  }

  private addTotals() {
    if (this.typeReport === 'PENDING') {
      this.customersTable.forEach((item) => {
        const keys = ['fullValue', 'totalCustomer'];
        keys.forEach((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            const prop = `pendingTotal${
              key.charAt(0).toUpperCase() + key.slice(1)
            }`;
            this.totals[prop] =
              (this.totals[prop] || 0) + Number.parseFloat(item[key]);
          }
        });
      });
    }

    if (this.typeReport === 'INVOICE') {
      this.customersTable.forEach((item) => {
        const keys = [
          'value',
          'discount',
          'interest',
          'pendingValue',
          'totalPaid',
        ];
        keys.forEach((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            const prop = `invoiceTotal${
              key.charAt(0).toUpperCase() + key.slice(1)
            }`;
            this.totals[prop] =
              (this.totals[prop] || 0) + Number.parseFloat(item[key]);
          }
        });
      });
    }
    if (this.typeReport === 'INVOICING') {
      this.customersTable.forEach((item) => {
        const keys = ['invoiceValue', 'nfsValue', 'receiptValue'];
        keys.forEach((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            const prop = `invoicingTotal${
              key.charAt(0).toUpperCase() + key.slice(1)
            }`;
            this.totals[prop] =
              (this.totals[prop] || 0) + Number.parseFloat(item[key]);
          }
        });
      });
    }
    if (this.typeReport === 'DETAIL_BOATS') {
      this.customersTable.forEach((item) => {
        const keys = [
          'commercialLength',
          'boatServicesValue',
          'boatServicesDiscount',
          'boatServicesDueDateDiscount',
          'boatServicesTotalValue',
        ];
        keys.forEach((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            const prop = `detailBoatsTotal${
              key.charAt(0).toUpperCase() + key.slice(1)
            }`;
            //console.log(item[key]);
            let val: number[] = [];

            if (key != 'commercialLength' && typeof item[key] === 'string') {
              let s = item[key].split(',');
              val = s.map((item) => {
                return Number.parseFloat(item);
              });
            } else if (key != 'commercialLength') {
              val = [item[key]];
            }

            if (val && val.length > 1) {
              const r = val.reduce((a, b) => a + b, 0);
              this.totals[prop] = (this.totals[prop] || 0) + r;
            } else {
              this.totals[prop] =
                (this.totals[prop] || 0) + Number.parseFloat(item[key]);
            }
          }
        });
      });
    }

    if (this.typeReport === 'DETAIL_CUSTOMER') {
      this.customersTable.forEach((item) => {
        const keys = [
          'customerServiceValue',
          'customerServiceDiscount',
          'customerServiceDueDateDiscount',
          'customerServiceTotal',
        ];
        keys.forEach((key) => {
          if (item[key] !== null && item[key] !== undefined) {
            const prop = `detailCustomerTotal${
              key.charAt(0).toUpperCase() + key.slice(1)
            }`;
            let val: number[] = item[key].split(',').map((item) => {
              return Number.parseFloat(item);
            });

            val.forEach((item) => {
              this.totals[prop] = (this.totals[prop] || 0) + item;
            });
          }
        });
      });
    }
  }

  extractImageFromDataUrl(dataUrl): void {
    dataUrl = dataUrl || '';
    const dataUrlParts = dataUrl.split('base64,');
    let result = null;
    if (dataUrlParts.length === 2) {
      const extractedInfo = /^data:(\w*\/\w*);*(charset=[\w=-]*)*;*$/.exec(
        dataUrlParts[0]
      );
      if (Array.isArray(extractedInfo)) {
        result = {
          mimeType: extractedInfo[1],
          charset: extractedInfo[2],
          data: dataUrlParts[1],
        };
      }
    }
    return result;
  }

  async getBase64Image(url: string): Promise<any> {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64 = reader.result;
        resolve(base64);
      };
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.dialogRef.destroy();
  }

  translateSailorTypes(types: string[]): string {
    let result: string = '';
    if (types?.length > 0) {
      types.forEach((item) => {
        if (item != '') {
          result +=
            this.messageUtil.translateKey('SAILOR-INFO.SAILOR-TYPE.' + item) +
            ', ';
        }
      });
    }
    return result;
  }

  getAnualValue(value: number): number {
    return value * 12;
  }
}
