import {
  CommonModule,
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { AtomModule } from './components/atom/atom.module';
import { DestiniesNavigationPlanComponent } from './components/movements/movement-form/movement-navigation-plan/destinies-navigation-plan/destinies-navigation-plan.component';
import { MovementNavigationPlanComponent } from './components/movements/movement-form/movement-navigation-plan/movement-navigation-plan.component';
import { MovementResponsiblesComponent } from './components/movements/movement-form/movement-responsibles/movement-responsibles.component';
import { ScheduleDateComponent } from './components/movements/movement-form/schedule-date/schedule-date.component';
import { PassengersComponent } from './components/passengers/passengers.component';
import { UserLogModalComponent } from './pages/logs/user-log/user-log-modal/user-log-modal.component';
import { AdjustmentViewComponent } from './pages/operational/movement/movement-appointment/adjustment-view/adjustment-view.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { RouteReuseStrategy } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { QRCodeModule } from 'angularx-qrcode';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { TimezoneUtil } from 'src/app/utils/timezone.util';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddressComponent } from './components/address/address.component';
import { BoatWashEngineTurnFormComponent } from './components/boat-wash-engine-turn/boat-wash-engine-turn-form/boat-wash-engine-turn-form.component';
import { BoatWashEngineTurnHistoryComponent } from './components/boat-wash-engine-turn/boat-wash-engine-turn-history/boat-wash-engine-turn-history.component';
import { ServiceBoatContractsDialogComponent } from './components/contracts/service-boat-contracts-dialog/service-boat-contracts-dialog.component';
import { SignerContractFormComponent } from './components/contracts/signers-contract-dialog/signer-contract-form/signer-contract-form.component';
import { SignersContractDialogComponent } from './components/contracts/signers-contract-dialog/signers-contract-dialog.component';
import { TableVerifyContractsComponent } from './components/contracts/table-verify-contracts/table-verify-contracts.component';
import { VerifyContractsDialogComponent } from './components/contracts/verify-contracts-dialog/verify-contracts-dialog.component';
import { DebitsItemsComponent } from './components/debits-items/debits-items.component';
import { ExtractInvoiceComponent } from './components/extract-invoice/extract-invoice.component';
import { ExtractPdfGenericComponent } from './components/extract-pdf-generic/extract-pdf-generic.component';
import { FilterBillingCompanyComponent } from './components/filter/filter-billing-company/filter-billing-company.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderButtonNewNavigationComponent } from './components/header/header-button-new-navigation/header-button-new-navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { ReceiptsIconComponent } from './components/icons/receipts-icon/receipts-icon.component';
import { InvoiceInterestDetailsComponent } from './components/invoice-interest-details/invoice-interest-details.component';
import { InvoicePaymentComponent } from './components/invoice-payment/invoice-payment.component';
import { ChangeDateDialogComponent } from './components/invoice/change-date-dialog/change-date-dialog.component';
import { DanfeUploadComponent } from './components/invoice/danfe-upload/danfe-upload.component';
import { InvoiceEmailsErrorsComponent } from './components/invoice/invoice-emails-errors/invoice-emails-errors.component';
import { InvoiceEmailsComponent } from './components/invoice/invoice-emails/invoice-emails.component';
import { InvoiceHelpComponent } from './components/invoice/invoice-help/invoice-help.component';
import { InvoicePaidsComponent } from './components/invoice/invoice-paids/invoice-paids.component';
import { MultipleReferenceDateComponent } from './components/invoice/multiple-reference-date/multiple-reference-date.component';
import { ParcelComponent } from './components/invoice/parcel/parcel.component';
import { LogFieldComponent } from './components/logs/log-field/log-field.component';
import { MarinaOrderLogTableComponent } from './components/marina-order/marina-order-log-table/marina-order-log-table.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MovementDataComponent } from './components/movements/movement-form/movement-data/movement-data.component';
import { MovementSelectBoatComponent } from './components/movements/movement-form/movement-responsibles/movement-select-boat/movement-select-boat.component';
import { MovementSelectCustomerComponent } from './components/movements/movement-form/movement-responsibles/movement-select-customer/movement-select-customer.component';
import { MovementSelectOriginMovementLocationComponent } from './components/movements/movement-form/movement-responsibles/movement-select-origin-movement-location/movement-select-origin-movement-location.component';
import { MovementSelectOriginVacancyComponent } from './components/movements/movement-form/movement-responsibles/movement-select-origin-vacancy/movement-select-origin-vacancy.component';
import { MovementSelectSailorComponent } from './components/movements/movement-form/movement-responsibles/movement-select-sailor/movement-select-sailor.component';
import { MovementObjectiveFormComponent } from './components/movements/movement-objective-form/movement-objective-form.component';
import { MovementPlanObjectiveQuestionnaireComponent } from './components/movements/movement-objective-form/movement-plan-objective-questionnaire/movement-plan-objective-questionnaire.component';
import { MovementPlanObjectivesFormComponent } from './components/movements/movement-objective-form/movement-plan-objectives-form/movement-plan-objectives-form.component';
import { MovementsOperationalModule } from './components/movements/movements-operational/movements-operational.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { QrcodesDialogComponent } from './components/qrcodes/qrcodes-dialog/qrcodes-dialog.component';
import { QrcodesPdfDialogComponent } from './components/qrcodes/qrcodes-pdf-dialog/qrcodes-pdf-dialog.component';
import { SailorUploadComponent } from './components/sailor-upload/sailor-upload.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CheckUserBraceletComponent } from './components/sling/check-user-bracelet/check-user-bracelet.component';
import { SlingAccessoryCheckComponent } from './components/sling/sling-accessory-check/sling-accessory-check.component';
import { SlingConfirmOperationComponent } from './components/sling/sling-confirm-operation/sling-confirm-operation.component';
import { SlingConsumableCheckComponent } from './components/sling/sling-consumable-check/sling-consumable-check.component';
import { SlingDownTabComponent } from './components/sling/sling-down-tab/sling-down-tab.component';
import { MultipleDestinationsComponent } from './components/sling/sling-form/multiple-destinations/multiple-destinations.component';
import { SlingAccessoryComponent } from './components/sling/sling-form/sling-accessory/sling-accessory.component';
import { SlingDataComponent } from './components/sling/sling-form/sling-data/sling-data.component';
import { SlingFormComponent } from './components/sling/sling-form/sling-form.component';
import { SlingFuelComponent } from './components/sling/sling-form/sling-fuel/sling-fuel.component';
import { SlingProductComponent } from './components/sling/sling-form/sling-product/sling-product.component';
import { SlingQuotaComponent } from './components/sling/sling-form/sling-quota/sling-quota.component';
import { SlingFuelCheckComponent } from './components/sling/sling-fuel-check/sling-fuel-check.component';
import { SlingLogDialogComponent } from './components/sling/sling-log-dialog/sling-log-dialog.component';
import { SlingLogTableComponent } from './components/sling/sling-log-table/sling-log-table.component';
import { SlingOperationComponent } from './components/sling/sling-operation/sling-operation.component';
import { SlingOrderDetailsComponent } from './components/sling/sling-order-details/sling-order-details.component';
import { SlingOwnFuelConfirmComponent } from './components/sling/sling-own-fuel-confirm/sling-own-fuel-confirm.component';
import { SlingProductFormComponent } from './components/sling/sling-product-form/sling-product-form.component';
import { SlingUpTabComponent } from './components/sling/sling-up-tab/sling-up-tab.component';
import { SlingWaterTabComponent } from './components/sling/sling-water-tab/sling-water-tab.component';
import { BoatWashEngineTurnFormDialogComponent } from './components/totem/boat-wash-engine-turn-panel/boat-wash-engine-turn-form-dialog/boat-wash-engine-turn-form-dialog.component';
import { BoatWashEngineTurnPanelComponent } from './components/totem/boat-wash-engine-turn-panel/boat-wash-engine-turn-panel.component';
import { ReprintComponent } from './components/totem/reprint/reprint.component';
import { OrderQuotaSelectComponent } from './components/totem/single-order/sales-checkout-wizard/order-quota-select/order-quota-select.component';
import { SingleOrderQuantityComponent } from './components/totem/single-order/single-order-quantity/single-order-quantity.component';
import { SingleOrderComponent } from './components/totem/single-order/single-order.component';
import { SlingTotemComponent } from './components/totem/sling-totem/sling-totem.component';
import { TotemDebitsComponent } from './components/totem/totem-debits/totem-debits.component';
import { NavigationPlanFormComponent } from './components/totem/totem-navigation-plan/navigation-plan-form/navigation-plan-form.component';
import { TotemNavigationPlanComponent } from './components/totem/totem-navigation-plan/totem-navigation-plan.component';
import { UserAppTabComponent } from './components/user/user-app-tab/user-app-tab.component';
import { UserMarinaTabComponent } from './components/user/user-marina-tab/user-marina-tab.component';
import { FiltersButtonDirective } from './directives/filters-button.directive';
import { FiltersDirective } from './directives/filters.directive';
import { HideMobileDirective } from './directives/hide-mobile.directive';
import { BoatMovementRowDirective } from './directives/movements/boat-movement-row.directive';
import { AuthGuard } from './guards/auth-guard';
import { CleanComponent } from './layouts/clean/clean.component';
import { DefaultComponent } from './layouts/default/default.component';
import { AnticipationDialogComponent } from './pages/billing/anticipation/anticipation-dialog/anticipation-dialog.component';
import { AnticipationComponent } from './pages/billing/anticipation/anticipation.component';
import { ManagementComponent } from './pages/billing/anticipation/management/management.component';
import { SummaryComponent } from './pages/billing/anticipation/summary/summary.component';
import { BilletDialogComponent } from './pages/billing/billets-management/billet/billet-dialog/billet-dialog.component';
import { BilletComponent } from './pages/billing/billets-management/billet/billet.component';
import { OutfileComponent } from './pages/billing/billets-management/outfile/outfile.component';
import { ResultDialogComponent } from './pages/billing/billets-management/outfile/result-dialog/result-dialog.component';
import { ContractsComponent } from './pages/billing/contracts/contracts.component';
import { NewContractDialogComponent } from './pages/billing/contracts/new-contract-dialog/new-contract-dialog.component';
import { VariablesContractDialogComponent } from './pages/billing/contracts/new-contract-dialog/variables-contract-dialog/variables-contract-dialog.component';
import { ServiceBoatWithContractsComponent } from './pages/billing/contracts/service-boat-with-contracts/service-boat-with-contracts.component';
import { SignContractDialogComponent } from './pages/billing/contracts/sign-contract-dialog/sign-contract-dialog.component';
import { DailyDebitsComponent } from './pages/billing/daily-debits/daily-debits.component';
import { DebitsComponent } from './pages/billing/debits/debits.component';
import { InvoiceTaxDialogComponent } from './pages/billing/invoice-tax-management/invoice-tax/invoice-tax-dialog/invoice-tax-dialog.component';
import { InvoiceTaxFormComponent } from './pages/billing/invoice-tax-management/invoice-tax/invoice-tax-form/invoice-tax-form.component';
import { InvoiceTaxComponent } from './pages/billing/invoice-tax-management/invoice-tax/invoice-tax.component';
import { InvoiceEmailDialogComponent } from './pages/billing/invoice/invoice-email-dialog/invoice-email-dialog.component';
import { InvoiceComponent } from './pages/billing/invoice/invoice.component';
import { DuedateSelectionComponent } from './pages/billing/order-invoice/duedate-selection/duedate-selection.component';
import { OrderInvoiceComponent } from './pages/billing/order-invoice/order-invoice.component';
import { OrderFormComponent } from './pages/billing/order/order-form/order-form.component';
import { OrderComponent } from './pages/billing/order/order.component';
import { BlockBoatDialogComponent } from './pages/billing/pending-customers/block-boat-dialog/block-boat-dialog.component';
import { ListNotBlockComponent } from './pages/billing/pending-customers/list-not-block/list-not-block.component';
import { PendingCustomersComponent } from './pages/billing/pending-customers/pending-customers.component';
import { ReceivingAccountsDialogComponent } from './pages/billing/receiving-accounts/receiving-accounts-dialog/receiving-accounts-dialog.component';
import { ReceivingAccountsComponent } from './pages/billing/receiving-accounts/receiving-accounts.component';
import { ServiceComparisonConfigurationModalComponent } from './pages/billing/service-comparison/service-comparison-configuration-modal/service-comparison-configuration-modal.component';
import { ServiceComparisonComponent } from './pages/billing/service-comparison/service-comparison.component';
import { TuitionRangeComponent } from './pages/billing/tuitions/tuition-range/tuition-range.component';
import { TuitionSummaryComponent } from './pages/billing/tuitions/tuition-summary/tuition-summary.component';
import { TuitionsComponent } from './pages/billing/tuitions/tuitions.component';
import { ContractsConfigDefaultSignerComponent } from './pages/configs/contracts-config-default-signer/contracts-config-default-signer.component';
import { DefaultSignerFormComponent } from './pages/configs/contracts-config-default-signer/default-signer-form/default-signer-form.component';
import { ContractsConfigTemplateComponent } from './pages/configs/contracts-config-template/contracts-config-template.component';
import { TemplateFormComponent } from './pages/configs/contracts-config-template/template-form/template-form.component';
import { MovementLocationsConfigComponent } from './pages/configs/movement-locations-config/movement-locations-config.component';
import { MovementGroupModalComponent } from './pages/configs/movements-config/movement-group/movement-group-modal/movement-group-modal.component';
import { MovementGroupComponent } from './pages/configs/movements-config/movement-group/movement-group.component';
import { MovementsConfigComponent } from './pages/configs/movements-config/movements-config.component';
import { NotaFiscalComponent } from './pages/configs/nota-fiscal/nota-fiscal.component';
import { NoticiesFormComponent } from './pages/configs/noticies/noticies-form/noticies-form.component';
import { NoticiesComponent } from './pages/configs/noticies/noticies.component';
import { CopyAndPasteMultipleModalComponent } from './pages/configs/operating-day-config/modals/copy-and-paste-multiple-modal/copy-and-paste-multiple-modal.component';
import { CreateScheduleQueueModalComponent } from './pages/configs/operating-day-config/modals/create-schedule-queue-modal/create-schedule-queue-modal.component';
import { ScheduleQueueFormModalComponent } from './pages/configs/operating-day-config/modals/schedule-queue-form-modal/schedule-queue-form-modal.component';
import { SpecialOperatingDayClosedModalComponent } from './pages/configs/operating-day-config/modals/special-operating-day-closed-modal/special-operating-day-closed-modal.component';
import { SpecialOperatingDayOpenModalComponent } from './pages/configs/operating-day-config/modals/special-operating-day-open-modal/special-operating-day-open-modal.component';
import { OperatingDayConfigComponent } from './pages/configs/operating-day-config/operating-day-config.component';
import { OperatingDayComponent } from './pages/configs/operating-day-config/operating-day/operating-day.component';
import { ScheduleQueueComponent } from './pages/configs/operating-day-config/schedule-queue/schedule-queue.component';
import { SpecialDayMarinaComponent } from './pages/configs/operating-day-config/special-day-marina/special-day-marina.component';
import { PanelConfigDialogComponent } from './pages/configs/panel-config/panel-config-dialog/panel-config-dialog.component';
import { PanelConfigFormComponent } from './pages/configs/panel-config/panel-config-form/panel-config-form.component';
import { PanelConfigComponent } from './pages/configs/panel-config/panel-config.component';
import { MovementSirenAlertConfigComponent } from './pages/configs/siren-alert-config/movement-siren-alert-config/movement-siren-alert-config.component';
import { SirenAlertConfigComponent } from './pages/configs/siren-alert-config/siren-alert-config.component';
import { SirenAlertComponent } from './pages/configs/siren-alert-config/siren-alert/siren-alert.component';
import { SlingSirenAlertConfigComponent } from './pages/configs/siren-alert-config/sling-siren-alert-config/sling-siren-alert-config.component';
import { FinancialConfigComponent } from './pages/configs/sling-config/sling-config-form/financial-config/financial-config.component';
import { OperationCloseDayComponent } from './pages/configs/sling-config/sling-config-form/operation-close-day/operation-close-day.component';
import { OperationSpecialDayComponent } from './pages/configs/sling-config/sling-config-form/operation-special-date/operation-special-day.component';
import { SlingConfigFormComponent } from './pages/configs/sling-config/sling-config-form/sling-config-form.component';
import { SlingConfigComponent } from './pages/configs/sling-config/sling-config.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FinancesComponent } from './pages/finances/finances.component';
import { BoatAccessoriesComponent } from './pages/forms/boat/boat-accessories/boat-accessories.component';
import { BoatContractComponent } from './pages/forms/boat/boat-contract/boat-contract.component';
import { BoatDetailServicesComponent } from './pages/forms/boat/boat-detail-services/boat-detail-services.component';
import { BoatFormComponent } from './pages/forms/boat/boat-form/boat-form.component';
import { BoatHistoryDialogComponent } from './pages/forms/boat/boat-form/boat-history-dialog/boat-history-dialog.component';
import { BoatSailorsTableComponent } from './pages/forms/boat/boat-form/boat-sailors-table/boat-sailors-table.component';
import { FuelService } from './pages/forms/boat/boat-form/fuel.service';
import { ServiceBoatHistoryComponent } from './pages/forms/boat/boat-form/service-boat-history/service-boat-history.component';
import { BoatComponent } from './pages/forms/boat/boat.component';
import { CustomerFormComponent } from './pages/forms/customer/customer-form/customer-form.component';
import { CustomerImportDialogComponent } from './pages/forms/customer/customer-import-dialog/customer-import-dialog.component';
import { CustomerImportsDialogComponent } from './pages/forms/customer/customer-imports-dialog/customer-imports-dialog.component';
import { CustomerComponent } from './pages/forms/customer/customer.component';
import { GroupFormComponent } from './pages/forms/group/group-form/group-form.component';
import { GroupComponent } from './pages/forms/group/group.component';
import { SailorBoatsailorComponent } from './pages/forms/sailor/sailor-form/sailor-boatsailor/sailor-boatsailor.component';
import { SailorDadosComponent } from './pages/forms/sailor/sailor-form/sailor-dados/sailor-dados.component';
import { SailorFormComponent } from './pages/forms/sailor/sailor-form/sailor-form.component';
import { SailorLicenseComponent } from './pages/forms/sailor/sailor-form/sailor-license/sailor-license.component';
import { SailorComponent } from './pages/forms/sailor/sailor.component';
import { UserRoleFormComponent } from './pages/forms/user-role/user-role-form/user-role-form.component';
import { UserRoleComponent } from './pages/forms/user-role/user-role.component';
import { UserFormComponent } from './pages/forms/user/user-form/user-form.component';
import { UserComponent } from './pages/forms/user/user.component';
import { LocationComponent } from './pages/forms/vacancy/vacancy-form/location/location.component';
import { VacancyFormComponent } from './pages/forms/vacancy/vacancy-form/vacancy-form.component';
import { VacancyComponent } from './pages/forms/vacancy/vacancy.component';
import { LoginComponent } from './pages/login/login.component';
import { BoatLogAccessoryAccessoryComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-accessory-accessory/boat-log-accessory-accessory.component';
import { BoatLogAccessoryOptionalComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-accessory-optional/boat-log-accessory-optional.component';
import { BoatLogAuthorizedComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-authorized/boat-log-authorized.component';
import { BoatLogCustomerComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-customer/boat-log-customer.component';
import { BoatLogGeneralComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-general/boat-log-general.component';
import { BoatLogHullComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-hull/boat-log-hull.component';
import { BoatLogInsurerComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-insurer/boat-log-insurer.component';
import { BoatLogModalComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-modal.component';
import { BoatLogOperatorComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-operator/boat-log-operator.component';
import { BoatLogServiceBoatComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-service-boat/boat-log-service-boat.component';
import { BoatLogTieComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-tie/boat-log-tie.component';
import { BoatLogTableComponent } from './pages/logs/boat-log/boat-log-table/boat-log-table.component';
import { BoatLogComponent } from './pages/logs/boat-log/boat-log.component';
import { ContractLogGeneralDataComponent } from './pages/logs/contract-log/contract-log-modal/contract-log-general-data/contract-log-general-data.component';
import { ContractLogModalComponent } from './pages/logs/contract-log/contract-log-modal/contract-log-modal.component';
import { ContractLogSignerComponent } from './pages/logs/contract-log/contract-log-modal/contract-log-signer/contract-log-signer.component';
import { ContractLogTableComponent } from './pages/logs/contract-log/contract-log-table/contract-log-table.component';
import { ContractLogComponent } from './pages/logs/contract-log/contract-log.component';
import { CustomerLogAddressComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-address/customer-log-address.component';
import { CustomerLogBillingNfComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-billing-nf/customer-log-billing-nf.component';
import { CustomerLogBillingComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-billing/customer-log-billing.component';
import { CustomerLogEmergencyContactComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-emergency-contact/customer-log-emergency-contact.component';
import { CustomerLogInformationComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-information/customer-log-information.component';
import { CustomerLogLicenceComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-licence/customer-log-licence.component';
import { CustomerLogModalComponent } from './pages/logs/customer-log/customer-log-modal/customer-log-modal.component';
import { CustomerLogTableComponent } from './pages/logs/customer-log/customer-log-table/customer-log-table.component';
import { CustomerLogComponent } from './pages/logs/customer-log/customer-log.component';
import { MarinaOrderLogComponent } from './pages/logs/marina-order-log/marina-order-log.component';
import { MovementExecutionOperationLogTableComponent } from './pages/logs/movement-execution-operation-log/movement-execution-operation-log-table/movement-execution-operation-log-table.component';
import { MovementExecutionOperationLogComponent } from './pages/logs/movement-execution-operation-log/movement-execution-operation-log.component';
import { ProductLogGeneralDataComponent } from './pages/logs/product-log/product-log-modal/product-log-general-data/product-log-general-data.component';
import { ProductLogImageComponent } from './pages/logs/product-log/product-log-modal/product-log-image/product-log-image.component';
import { ProductLogModalComponent } from './pages/logs/product-log/product-log-modal/product-log-modal.component';
import { ProductLogPriceRangeComponent } from './pages/logs/product-log/product-log-modal/product-log-price/product-log-price-range/product-log-price-range.component';
import { ProductLogPriceComponent } from './pages/logs/product-log/product-log-modal/product-log-price/product-log-price.component';
import { ProductLogStockComponent } from './pages/logs/product-log/product-log-modal/product-log-stock/product-log-stock.component';
import { ProductLogTableComponent } from './pages/logs/product-log/product-log-table/product-log-table.component';
import { ProductLogComponent } from './pages/logs/product-log/product-log.component';
import { SlingLogComponent } from './pages/logs/sling-log/sling-log.component';
import { SlingShareLogComponent } from './pages/logs/sling-share-log/sling-share-log.component';
import { UserSmsLogComponent } from './pages/logs/user-sms-log/user-sms-log.component';
import { DocumentsContractedComponent } from './pages/my-account/documents-contracted/documents-contracted.component';
import { MyInvoiceComponent } from './pages/my-account/my-invoice/my-invoice.component';
import { MovementAdjustmentDialogComponent } from './pages/operational/movement-management/movement-adjustment-dialog/movement-adjustment-dialog.component';
import { MovementManagementComponent } from './pages/operational/movement-management/movement-management.component';
import { VerificationMovementAdjustmentDialogComponent } from './pages/operational/movement-management/verification-movement-adjustment-dialog/verification-movement-adjustment-dialog.component';
import { MovementExecutionEditModalComponent } from './pages/operational/movement/movement-appointment/modals/movement-execution-edit-modal/movement-execution-edit-modal.component';
import { MovementExecutionFinishModalComponent } from './pages/operational/movement/movement-appointment/modals/movement-execution-finish-modal/movement-execution-finish-modal.component';
import { MovementPlainViewComponent } from './pages/operational/movement/movement-appointment/modals/movement-plain-view/movement-plain-view.component';
import { MovementAppointmentComponent } from './pages/operational/movement/movement-appointment/movement-appointment.component';
import { MovementExecutionComponent } from './pages/operational/movement/movement-appointment/movement-execution/movement-execution.component';
import { MovementLocationNavigationComponent } from './pages/operational/movement/movement-appointment/movement-location-navigation/movement-location-navigation.component';
import { MovementLocationYardComponent } from './pages/operational/movement/movement-appointment/movement-location-yard/movement-location-yard.component';
import { MovementPlanComponent } from './pages/operational/movement/movement-plan/movement-plan.component';
import { NavigationPlanComponent } from './pages/operational/navigation-plan/navigation-plan.component';
import { SlingCheckComponent } from './pages/operational/sling-check/sling-check.component';
import { SlingComponent } from './pages/operational/sling/sling.component';
import { WashTabComponent } from './pages/operational/wash-engine-turn/boat-wash-tab/wash-tab.component';
import { EngineTurnTabComponent } from './pages/operational/wash-engine-turn/engine-turn-tab/engine-turn-tab.component';
import { WashEngineTurnComponent } from './pages/operational/wash-engine-turn/wash-engine-turn.component';
import { PivotProductSalesComponent } from './pages/pivot/pivot-product-sales/pivot-produtc-sales.component';
import { BoatsQrcodesComponent } from './pages/qrcodes/boats-qrcodes/boats-qrcodes.component';
import { VacanciesQrcodesComponent } from './pages/qrcodes/vacancies-qrcodes/vacancies-qrcodes.component';
import { ProductHistoryComponent } from './pages/reports/product-history/product-history.component';
import { BalanceAdjustmentComponent } from './pages/stock/balance-adjustment/balance-adjustment.component';
import { ProductFormComponent } from './pages/stock/product/product-form/product-form.component';
import { ReadjustmentsProductTableComponent } from './pages/stock/product/product-form/readjustments-product-table/readjustments-product-table.component';
import { ProductComponent } from './pages/stock/product/product.component';
import { ListPriceFreeComponent } from './pages/stock/service-readjustment/list-price-free/list-price-free.component';
import { ReajustmentHistoricComponent } from './pages/stock/service-readjustment/list-price-free/reajustment-historic/reajustment-historic/reajustment-historic.component';
import { ConfigPriceRangesComponent } from './pages/stock/service-readjustment/list-services/config-price-ranges/config-price-ranges.component';
import { ConfigPriceSimplesComponent } from './pages/stock/service-readjustment/list-services/config-price-simples/config-price-simples.component';
import { ListServicesComponent } from './pages/stock/service-readjustment/list-services/list-services.component';
import { TableServiceReadjustmentComponent } from './pages/stock/service-readjustment/list-services/table-service-readjustment/table-service-readjustment.component';
import { ServiceReadjustmentComponent } from './pages/stock/service-readjustment/service-readjustment.component';
import { AccessoryService } from './services/accessory.service';
import { AnticipationLotService } from './services/anticipation-lot.service';
import { AuthenticationService } from './services/authentication.service';
import { BilletService } from './services/billet.service';
import { BoatAccessorieService } from './services/boat-accessorie.service';
import { BoatLocationService } from './services/boat-location.service';
import { BoatLogService } from './services/boat-log.service';
import { BoatWashService } from './services/boat-wash.service';
import { BoatService } from './services/boat.service';
import { BroadcastService } from './services/broadcast.service';
import { ContractDocumentService } from './services/contract-document.service';
import { ContractLogService } from './services/contract-log.service';
import { ContractModelMarinaService } from './services/contract-model-marina.service';
import { CustomReportService } from './services/custom-report.service';
import { CustomerLogService } from './services/customer-log.service';
import { CustomerService } from './services/customer.service';
import { DashboardService } from './services/dashboard.service';
import { DocumentContractedMarinaService } from './services/document-contracted-marina.service';
import { DocumentDefaultSignerService } from './services/document-default-signer.service';
import { DocumentSignerService } from './services/document-signer.service';
import { DocumentTemplateService } from './services/document-template.service';
import { EasyMarineHttpApiLog } from './services/easy-marine-http-api-log';
import { EmailAccountService } from './services/email-account.service';
import { EngineTurnService } from './services/engine-turn.service';
import { FinancesService } from './services/finances.service';
import { FocusFieldService } from './services/focus-field.service';
import { GroupService } from './services/group.service';
import { ImportResultService } from './services/import-result.service';
import { InsurerService } from './services/insurer.service';
import { HttpsRequestInterceptor } from './services/interceptor.module';
import { InvoicePaidService } from './services/invoice-paid.service';
import { InvoiceReceiptService } from './services/invoice-receipt.service';
import { InvoiceTaxDocumentService } from './services/invoice-tax-document.service';
import { InvoiceService } from './services/invoice.service';
import { LocationService } from './services/location.service';
import { MarinaContractPriceService } from './services/marina-contract-price.service';
import { MarinaOrderLogService } from './services/marina-order-log.service';
import { MarinaOrderService } from './services/marina-order.service';
import { MarinaTermUseService } from './services/marina-term-use.service';
import { MenuItemService } from './services/menu-item.service';
import { MovementExecutionOperationLogService } from './services/movement-execution-operation-log.service';
import { MovementGroupService } from './services/movements/movement-group.service';
import { MovementImageService } from './services/movements/movement-image.service';
import { MovementManagementService } from './services/movements/movement-management.service';
import { MovementMeanService } from './services/movements/movement-mean.service';
import { MovementReportService } from './services/movements/movement-report.service';
import { MyAccountService } from './services/my-account.service';
import { NavigationPlanService } from './services/navigation-plan.service';
import { NotaFiscalService } from './services/nota-fiscal.service';
import { NoticeService } from './services/notice.service';
import { OrderItemService } from './services/order-item.service';
import { PanelConfigService } from './services/panel-config.service';
import { PivotTableService } from './services/pivot-table.services';
import { ProductCategoryService } from './services/product-category.service';
import { ProductLogService } from './services/product-log.service';
import { ProductService } from './services/product.service';
import { RaspberryService } from './services/raspberry.service';
import { RoleService } from './services/role.service';
import { ServiceBoatService } from './services/service-boat.service';
import { ServiceComparisonService } from './services/service-comparison.service';
import { ShipyardService } from './services/shipyard.service';
import { ShortUrlService } from './services/short-url.service';
import { SirenAlertConfigService } from './services/siren/siren-alert-config.service';
import { SirenAlertService } from './services/siren/siren-alert.service';
import { SlingAccessoryService } from './services/sling-accessory.service';
import { SlingConfigService } from './services/sling-config.service';
import { SlingLogService } from './services/sling-log.service';
import { SlingService } from './services/sling.service';
import { ToastService } from './services/toast.service';
import { TuitionService } from './services/tuition.service';
import { UserService } from './services/user.service';
import { VacancyService } from './services/vacancy.service';
import { ValidatorsUtil } from './services/validators.util';
import { VariableTemplateService } from './services/variable-template';
import { ContractUtil } from './utils/contract.util';
import { CustomRouteReuseStrategy } from './utils/custom-route-reuse-strategy';
import {
  EasyMarineHttpFinances,
  easyMarineHttpFinancesCreator,
} from './utils/easy-marine-http-finances';
import { ImageUtil } from './utils/image.util';
import { MessageSlingDayUtil } from './utils/message-sling-day.util';
import { MessageUtil } from './utils/message.util';
import { OrderReferenceDateUtil } from './utils/order-reference-date.util';
import { OrderUtil } from './utils/order.util';
import { RoleUtil } from './utils/role.util';
import { SlingUtil } from './utils/sling.util';
import { StorageUtil } from './utils/storage.util';

import { CustomReportExportComponent } from './components/extract-custom-report/custom-report-export.component';
import { FaqDialogViewComponent } from './components/faq-dialog/faq-dialog-view/faq-dialog-view.component';
import { FaqDialogComponent } from './components/faq-dialog/faq-dialog.component';
import { ConfirmCompetencyDialogComponent } from './pages/billing/invoice/confirm-competency-dialog/confirm-competency-dialog.component';
import { InvoiceDiscountComponent } from './pages/billing/invoice/invoice-discount/invoice-discount.component';
import { LocationInvoiceComponent } from './pages/billing/location-invoice/location-invoice.component';
import { NoticieEmailDetailsComponent } from './pages/configs/noticies/noticies-emails/noticie-email-details/noticie-email-details.component';
import { NoticiesEmailsComponent } from './pages/configs/noticies/noticies-emails/noticies-emails.component';
import { LoginMarinaOptionsComponent } from './pages/login/login-marina-options/login-marina-options.component';
import { CreateTicketComponent } from './pages/my-account/ticket/ticket-open/create-ticket/create-ticket.component';
import { PersonalHelpComponent } from './pages/my-account/ticket/ticket-open/personal-help/personal-help.component';
import { TicketOpenComponent } from './pages/my-account/ticket/ticket-open/ticket-open.component';
import { WriteTicketComponent } from './pages/my-account/ticket/ticket-open/write-ticket/write-ticket.component';
import { TicketComponent } from './pages/my-account/ticket/ticket.component';

import { SidebarModule } from 'ng-sidebar-v2';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { BerthMapComponent } from './berth-map/berth-map.component';
import { BerthContextMenuComponent } from './berth-map/context-menu/berth-context-menu.component';
import { BerthOperationMenuComponent } from './berth-map/context-menu/berth-operation-menu.component';
import { MapContextMenuComponent } from './berth-map/context-menu/map-context-menu.component';
import { BerthMapInfoFormComponent } from './berth-map/info-form/berth-map-info-form.component';
import { CustomDrawerComponent } from './components/custom-drawer/custom-drawer.component';
import { HeaderButtonNotificationComponent } from './components/header/header-button-notification/header-button-notification.component';
import { ChooseBilletComponent } from './components/invoice-payment/choose-billet/choose-billet.component';
import { BoatCustomerSelectComponent } from './components/totem/single-order/sales-checkout-wizard/boat-customer-select/boat-customer-select.component';
import { DiscountSelectComponent } from './components/totem/single-order/sales-checkout-wizard/discount-select/discount-select.component';
import { PaymentFormSelectComponent } from './components/totem/single-order/sales-checkout-wizard/payment-form-select/payment-form-select.component';
import { SalesCheckoutWizardComponent } from './components/totem/single-order/sales-checkout-wizard/sales-checkout-wizard.component';
import { SimpleCadCustomerComponent } from './components/totem/single-order/sales-checkout-wizard/simple-cad-customer/simple-cad-customer.component';
import { SidenavBarGuard } from './guards/sidenav-bar.guard';
import { EmailDownloadComponent } from './pages/billing/email-download/email-download.component';
import { ImportNfpComponent } from './pages/billing/invoice-tax-management/invoice-tax-product/import-nfp/import-nfp.component';
import { InvoiceTaxProductComponent } from './pages/billing/invoice-tax-management/invoice-tax-product/invoice-tax-product.component';
import { EmitterNfComponent } from './pages/billing/invoice/emitter-nf/emitter-nf.component';
import { InvoiceUploadNfComponent } from './pages/billing/invoice/invoice-upload-nf/invoice-upload-nf.component';
import { InvoicingReportComponent } from './pages/billing/invoicing-report/invoicing-report.component';
import { BoatTuitionsComponent } from './pages/billing/tuitions/boat-tuitions/boat-tuitions.component';
import { CustomerTuitionsComponent } from './pages/billing/tuitions/customer-tuitions/customer-tuitions.component';
import { InvoiceGeneratedByTuitionComponent } from './pages/billing/tuitions/tuition-range/invoice-generated-by-tuition/invoice-generated-by-tuition.component';
import { RangeMapComponent } from './pages/configs/sling-config/sling-config-form/range-map/range-map.component';
import { NixxBankConfigComponent } from './pages/easy-bank/nixx-bank/nixx-bank-config/nixx-bank-config.component';
import { NixxBankLogsTableComponent } from './pages/easy-bank/nixx-bank/nixx-bank-logs/nixx-bank-logs-table/nixx-bank-logs-table.component';
import { NixxBankLogsComponent } from './pages/easy-bank/nixx-bank/nixx-bank-logs/nixx-bank-logs.component';
import { CreateProgramacaoDialogComponent } from './pages/finances/create-programacao-dialog/create-programacao-dialog.component';
import { CustomerServiceComponent } from './pages/forms/customer/customer-service/customer-service.component';
import { ServiceCustomerHistoryComponent } from './pages/forms/customer/customer-service/service-customer-history/service-customer-history.component';
import { BoatLogSailorComponent } from './pages/logs/boat-log/boat-log-modal/boat-log-sailor/boat-log-sailor.component';
import { InvoiceTaxLogsTableComponent } from './pages/logs/invoice-tax-logs/invoice-tax-logs-table/invoice-tax-logs-table.component';
import { InvoiceTaxLogsComponent } from './pages/logs/invoice-tax-logs/invoice-tax-logs.component';
import { RoleLogGeralComponent } from './pages/logs/role-log/role-log-geral/role-log-geral.component';
import { RoleLogModalComponent } from './pages/logs/role-log/role-log-modal/role-log-modal.component';
import { RoleLogTableComponent } from './pages/logs/role-log/role-log-table/role-log-table.component';
import { RoleLogComponent } from './pages/logs/role-log/role-log.component';
import { UserLogGeneralDataComponent } from './pages/logs/user-log/user-log-general-data/user-log-general-data.component';
import { UserLogTableComponent } from './pages/logs/user-log/user-log-table/user-log-table.component';
import { UserLogComponent } from './pages/logs/user-log/user-log.component';
import { MyMarineComponent } from './pages/my-account/my-marine/my-marine.component';
import { SuperInvoicingReportComponent } from './pages/my-account/super-invoicing-report/super-invoicing-report.component';
import { FranchiseControllerComponent } from './pages/operational/franchise-controller/franchise-controller.component';
import { LandExitComponent } from './pages/operational/land-exit/land-exit.component';
import { StockMovementFormComponent } from './pages/stock/stock-movement/stock-movement-form/stock-movement-form.component';
import { StockMovementComponent } from './pages/stock/stock-movement/stock-movement.component';
import { StockMovementTypeFormComponent } from './pages/stock/stock-movent-type/stock-movement-type-form/stock-movement-type-form.component';
import { StockMoventTypeComponent } from './pages/stock/stock-movent-type/stock-movent-type.component';
import { WorkshopComponent } from './pages/workshop/workshop.component';
import { InvoiceTaxLogService } from './services/invoice-tax-log.service';
import { LandExitService } from './services/land-exit.service';
import { MarinaImplantationService } from './services/marina-implantation.service';
import { WorkshopService } from './services/workshop.service';

import { DividerModule } from 'primeng/divider';
import { SliderModule } from 'primeng/slider';
import { ProcessElementComponent } from './components/totem/single-order/sales-checkout-wizard/process-element/process-element.component';
import { SplitBillComponent } from './components/totem/single-order/sales-checkout-wizard/split-bill/split-bill.component';
import { SplittedPaymentsComponent } from './components/totem/single-order/sales-checkout-wizard/split-bill/splitted-payments/splitted-payments.component';
import { DebounceClickDirective } from './directives/debounce/debounce-click.directive';
import { AccessCategoriesBatchComponent } from './pages/forms/access-controller/access-group/access-categories-batch/access-categories-batch.component';
import { AccessGroupFormComponent } from './pages/forms/access-controller/access-group/access-group-form/access-group-form.component';
import { AccessGroupComponent } from './pages/forms/access-controller/access-group/access-group.component';
import { AccessPointFormComponent } from './pages/forms/access-controller/access-point/access-point-form/access-point-form.component';
import { AccessPointComponent } from './pages/forms/access-controller/access-point/access-point.component';
import { VisitorFormPermissionComponent } from './pages/forms/access-controller/visitor/service-provider-forms-container/visitor-form-permission/visitor-form-permission.component';
import { VisitorFormComponent } from './pages/forms/access-controller/visitor/service-provider-forms-container/visitor-form/visitor-form.component';
import { ServiceProviderCenterComponent } from './pages/forms/access-controller/visitor/service-provider-center/service-provider-center.component';
import { CustomerDependentComponent } from './pages/forms/customer/customer-dependent/customer-dependent.component';
import { ServiceCustomerOrdersHistoryComponent } from './pages/forms/customer/customer-service/service-customer-orders-history/service-customer-orders-history.component';
import { SecuritiesPropertyCadLotComponent } from './pages/forms/securities-property/securities-property-cad-lot/securities-property-cad-lot.component';
import { SecuritiesPropertyEditComponent } from './pages/forms/securities-property/securities-property-edit/securities-property-edit.component';
import { SecuritiesPropertyHistComponent } from './pages/forms/securities-property/securities-property-hist/securities-property-hist.component';
import { SecuritiesPropertyInviteUseComponent } from './pages/forms/securities-property/securities-property-invite-use/securities-property-invite-use.component';
import { SecuritiesPropertyComponent } from './pages/forms/securities-property/securities-property.component';
import { AccessGroupLogComponent } from './pages/logs/access-group-log/access-group-log.component';
import { InvoiceLogTableComponent } from './pages/logs/invoice-log/invoice-log-table/invoice-log-table.component';
import { InvoiceLogComponent } from './pages/logs/invoice-log/invoice-log.component';
import { SelectDatesComponent } from './pages/operational/wash-engine-turn/select-dates/select-dates.component';
import { InvoiceLogService } from './services/invoice-log.service';
import { ServiceHistoryComponent } from './pages/reports/service-history/service-history.component';
import { InvoiceLogModalComponent } from './pages/logs/invoice-log/invoice-log-modal/invoice-log-modal.component';
import { PrintingService } from './services/printing-service';
import { BarcodeIconComponent } from './components/invoice/barcode-icon/barcode-icon.component';
import { FranchiseGroupComponent } from './pages/forms/franchise-group/franchise-group.component';
import { FranchiseGroupFormComponent } from './pages/forms/franchise-group/franchise-group-form/franchise-group-form.component';
import { OrderHistoricComponent } from './pages/billing/order/order-historic/order-historic.component';
import { FranchiseHistoryComponent } from './pages/operational/franchise-controller/franchise-history/franchise-history.component';
import { FastCustomerEditComponent } from './pages/forms/customer/fast-customer-edit/fast-customer-edit/fast-customer-edit.component';
import { FastCustomerEditFormComponent } from './pages/forms/customer/fast-customer-edit/fast-customer-edit-form/fast-customer-edit-form.component';
import { FranchiseEditqtymodalComponent } from './pages/operational/franchise-controller/franchise-editqtymodal/franchise-editqtymodal.component';
import { FranchiseGroupBatchComponent } from './pages/forms/franchise-group/franchise-group-batch/franchise-group-batch.component';
import { ClipboardModule } from 'ngx-clipboard';

import { VisitorPanelComponent } from './pages/forms/access-controller/visitor/visitor-panel/visitor-panel.component';
import { VisitorComponent } from './pages/forms/access-controller/visitor/visitor-component/visitor.component';
import { ServiceProviderFormsContainerComponent } from './pages/forms/access-controller/visitor/service-provider-forms-container/service-provider-forms-container.component';
import { VisitorFormHistoricComponent } from './pages/forms/access-controller/visitor/visitor-panel/visitor-form-historic/visitor-form-historic.component';
import { GuestCenterComponent } from './pages/forms/access-controller/visitor/guest-center/guest-center.component';
import { ProcessControlFormComponent } from './components/process-control-form/process-control-form.component';
import { CustomerCreditPageComponent } from './pages/customer-credit-page/customer-credit-page.component';
import { CustomerCreditHistoricComponent } from './pages/customer-credit-page/customer-credit-historic/customer-credit-historic.component';
import { CustomerCreditLaunchComponent } from './pages/customer-credit-page/customer-credit-launch/customer-credit-launch.component';
import { BoatReportComponent } from './pages/my-account/boat-report/boat-report.component';
import { BoatEnginesTableComponent } from './pages/forms/boat/boat-form/boat-engines-table/boat-engines-table.component';
import { CustomerServicesDetailsComponent } from './pages/reports/customer-services-details/customer-services-details.component';
import { DueExpireTuitionsComponent } from './pages/billing/tuitions/due-expire-tuitions/due-expire-tuitions.component';
import { ReportAccessControlerComponent } from './pages/acesscontroler/report-access-controler/report-access-controler.component';

registerLocaleData(localePt);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    CleanComponent,
    DefaultComponent,
    SlingComponent,
    SlingCheckComponent,
    WashEngineTurnComponent,
    NavigationPlanComponent,
    EngineTurnTabComponent,
    WashTabComponent,
    BoatWashEngineTurnFormComponent,
    BoatWashEngineTurnHistoryComponent,
    SlingDownTabComponent,
    SlingWaterTabComponent,
    SlingUpTabComponent,
    SlingConfirmOperationComponent,
    SlingOperationComponent,
    SlingOrderDetailsComponent,
    SlingOwnFuelConfirmComponent,
    SlingFuelCheckComponent,
    SlingConsumableCheckComponent,
    SlingProductFormComponent,
    SlingAccessoryCheckComponent,
    CheckUserBraceletComponent,
    SlingTotemComponent,
    SlingFormComponent,
    ReprintComponent,
    TotemDebitsComponent,
    InvoicePaymentComponent,
    ExtractInvoiceComponent,
    DebitsItemsComponent,
    ReceivingAccountsComponent,
    InvoiceInterestDetailsComponent,
    NavigationPlanFormComponent,
    TotemNavigationPlanComponent,
    BoatWashEngineTurnPanelComponent,
    BoatWashEngineTurnFormDialogComponent,
    SingleOrderComponent,
    SingleOrderQuantityComponent,
    OrderQuotaSelectComponent,
    TuitionsComponent,
    TuitionSummaryComponent,
    BoatFormComponent,
    BoatAccessoriesComponent,
    BoatContractComponent,
    TuitionRangeComponent,
    OrderComponent,
    OrderFormComponent,
    OrderInvoiceComponent,
    DuedateSelectionComponent,
    InvoiceComponent,
    InvoiceEmailsComponent,
    InvoiceEmailsErrorsComponent,
    InvoiceHelpComponent,
    InvoicePaidsComponent,
    DanfeUploadComponent,
    ParcelComponent,
    DebitsComponent,
    DailyDebitsComponent,
    PendingCustomersComponent,
    ReceivingAccountsDialogComponent,
    OutfileComponent,
    BilletComponent,
    ResultDialogComponent,
    BilletDialogComponent,
    AnticipationComponent,
    AnticipationDialogComponent,
    ManagementComponent,
    SummaryComponent,
    FinancesComponent,
    GroupComponent,
    GroupFormComponent,
    UserComponent,
    UserFormComponent,
    UserAppTabComponent,
    UserMarinaTabComponent,
    CustomerComponent,
    CustomerFormComponent,
    CustomerImportDialogComponent,
    CustomerImportsDialogComponent,
    BoatComponent,
    VacancyComponent,
    VacancyFormComponent,
    ProductComponent,
    ProductFormComponent,
    BalanceAdjustmentComponent,
    MyInvoiceComponent,
    MyMarineComponent,
    SlingLogComponent,
    SlingLogTableComponent,
    MarinaOrderLogComponent,
    MarinaOrderLogTableComponent,
    SlingShareLogComponent,
    SlingLogDialogComponent,
    BoatsQrcodesComponent,
    VacanciesQrcodesComponent,
    QrcodesDialogComponent,
    QrcodesPdfDialogComponent,
    NoticiesComponent,
    NoticiesFormComponent,
    SirenAlertConfigComponent,
    PanelConfigComponent,
    SlingConfigComponent,
    MovementsConfigComponent,
    SlingConfigFormComponent,
    ContractsComponent,
    NewContractDialogComponent,
    SignContractDialogComponent,
    SignersContractDialogComponent,
    VariablesContractDialogComponent,
    VerifyContractsDialogComponent,
    SlingDataComponent,
    PassengersComponent,
    SlingFuelComponent,
    SlingProductComponent,
    SlingAccessoryComponent,
    SlingQuotaComponent,
    UserRoleComponent,
    UserRoleFormComponent,
    HideMobileDirective,
    FiltersDirective,
    FiltersButtonDirective,
    DocumentsContractedComponent,
    BoatDetailServicesComponent,
    ExtractPdfGenericComponent,
    InvoiceTaxComponent,
    InvoiceTaxDialogComponent,
    InvoiceTaxFormComponent,
    NotaFiscalComponent,
    ContractsConfigTemplateComponent,
    TemplateFormComponent,
    ContractsConfigDefaultSignerComponent,
    DefaultSignerFormComponent,
    SignerContractFormComponent,
    TableVerifyContractsComponent,
    OperationSpecialDayComponent,
    OperationCloseDayComponent,
    ServiceBoatHistoryComponent,
    ServiceBoatWithContractsComponent,
    ServiceBoatContractsDialogComponent,
    BoatLogTableComponent,
    BoatLogComponent,
    BoatLogModalComponent,
    LogFieldComponent,
    CustomerLogComponent,
    ProductLogComponent,
    BoatLogGeneralComponent,
    BoatLogCustomerComponent,
    BoatLogAuthorizedComponent,
    BoatLogHullComponent,
    BoatLogTieComponent,
    BoatLogInsurerComponent,
    BoatLogOperatorComponent,
    BoatLogAccessoryOptionalComponent,
    BoatLogAccessoryAccessoryComponent,
    BoatLogServiceBoatComponent,
    CustomerLogTableComponent,
    CustomerLogModalComponent,
    CustomerLogInformationComponent,
    CustomerLogAddressComponent,
    CustomerLogEmergencyContactComponent,
    CustomerLogLicenceComponent,
    CustomerLogBillingComponent,
    CustomerLogBillingNfComponent,
    ProductLogModalComponent,
    ProductLogTableComponent,
    ProductLogGeneralDataComponent,
    ProductLogStockComponent,
    ProductLogPriceComponent,
    ProductLogImageComponent,
    ContractLogComponent,
    ContractLogModalComponent,
    ContractLogTableComponent,
    ContractLogSignerComponent,
    ContractLogGeneralDataComponent,
    ProductLogPriceRangeComponent,
    MultipleReferenceDateComponent,
    MultipleDestinationsComponent,
    BoatHistoryDialogComponent,
    UserSmsLogComponent,
    PivotProductSalesComponent,
    PanelConfigDialogComponent,
    PanelConfigFormComponent,
    ChangeDateDialogComponent,
    NotificationsComponent,
    BoatSailorsTableComponent,
    FinancialConfigComponent,
    FilterBillingCompanyComponent,
    ServiceReadjustmentComponent,
    ListPriceFreeComponent,
    ListServicesComponent,
    TableServiceReadjustmentComponent,
    ServiceComparisonComponent,
    ServiceComparisonConfigurationModalComponent,
    ReadjustmentsProductTableComponent,
    ConfigPriceSimplesComponent,
    ConfigPriceRangesComponent,
    MovementsConfigComponent,
    InvoiceEmailDialogComponent,
    ReceiptsIconComponent,
    MovementDataComponent,
    MovementResponsiblesComponent,
    ScheduleDateComponent,
    MovementNavigationPlanComponent,
    DestiniesNavigationPlanComponent,
    MovementGroupComponent,
    MovementGroupModalComponent,
    MovementAppointmentComponent,
    MovementExecutionComponent,
    MovementLocationYardComponent,
    MovementLocationNavigationComponent,
    MovementExecutionFinishModalComponent,
    SlingSirenAlertConfigComponent,
    MovementSirenAlertConfigComponent,
    SirenAlertComponent,
    BoatMovementRowDirective,
    HeaderButtonNewNavigationComponent,
    MovementExecutionOperationLogComponent,
    MovementExecutionOperationLogTableComponent,
    MovementSelectBoatComponent,
    MovementSelectCustomerComponent,
    MovementSelectSailorComponent,
    MovementPlanComponent,
    LocationComponent,
    MovementObjectiveFormComponent,
    MovementPlanObjectivesFormComponent,
    MovementPlanObjectiveQuestionnaireComponent,
    MessagesComponent,
    AdjustmentViewComponent,
    MovementPlainViewComponent,
    MovementExecutionEditModalComponent,
    OperatingDayConfigComponent,
    OperatingDayComponent,
    ScheduleQueueComponent,
    ScheduleQueueFormModalComponent,
    SpecialDayMarinaComponent,
    CreateScheduleQueueModalComponent,
    CopyAndPasteMultipleModalComponent,
    SpecialOperatingDayClosedModalComponent,
    SpecialOperatingDayOpenModalComponent,
    MovementManagementComponent,
    MovementLocationsConfigComponent,
    MovementAdjustmentDialogComponent,
    MovementSelectOriginVacancyComponent,
    MovementSelectOriginMovementLocationComponent,
    ProductHistoryComponent,
    VerificationMovementAdjustmentDialogComponent,
    ReajustmentHistoricComponent,
    SailorComponent,
    SailorFormComponent,
    SailorDadosComponent,
    SailorLicenseComponent,
    SailorBoatsailorComponent,
    SailorUploadComponent,
    ListNotBlockComponent,
    BlockBoatDialogComponent,

    ConfirmCompetencyDialogComponent,
    LocationInvoiceComponent,
    CustomReportExportComponent,
    LoginMarinaOptionsComponent,

    FaqDialogComponent,
    FaqDialogViewComponent,
    TicketComponent,
    TicketOpenComponent,
    CreateTicketComponent,
    PersonalHelpComponent,
    WriteTicketComponent,

    NoticiesEmailsComponent,
    NoticieEmailDetailsComponent,
    InvoiceDiscountComponent,
    WorkshopComponent,
    InvoicingReportComponent,
    InvoiceTaxLogsComponent,
    InvoiceTaxLogsTableComponent,

    ConfirmCompetencyDialogComponent,
    LocationInvoiceComponent,
    CustomReportExportComponent,
    LoginMarinaOptionsComponent,
    BoatLogSailorComponent,

    UserLogComponent,
    UserLogTableComponent,
    UserLogModalComponent,
    UserLogGeneralDataComponent,
    RoleLogComponent,
    RoleLogTableComponent,
    RoleLogModalComponent,
    RoleLogGeralComponent,
    NixxBankLogsComponent,
    NixxBankLogsTableComponent,
    NixxBankConfigComponent,
    RangeMapComponent,
    HeaderButtonNotificationComponent,
    EmailDownloadComponent,
    EmitterNfComponent,
    InvoiceTaxProductComponent,
    LandExitComponent,
    InvoiceGeneratedByTuitionComponent,
    ChooseBilletComponent,
    MyMarineComponent,
    ImportNfpComponent,
    SimpleCadCustomerComponent,
    InvoiceUploadNfComponent,
    SuperInvoicingReportComponent,
    CustomDrawerComponent,
    BoatCustomerSelectComponent,
    PaymentFormSelectComponent,
    SalesCheckoutWizardComponent,
    CustomDrawerComponent,
    BoatCustomerSelectComponent,
    PaymentFormSelectComponent,
    SalesCheckoutWizardComponent,
    CustomDrawerComponent,
    BerthMapComponent,
    MapContextMenuComponent,
    BerthContextMenuComponent,
    BerthOperationMenuComponent,
    BerthMapInfoFormComponent,
    StockMoventTypeComponent,
    StockMovementTypeFormComponent,
    StockMovementComponent,
    StockMovementFormComponent,
    DiscountSelectComponent,
    FranchiseControllerComponent,
    CreateProgramacaoDialogComponent,
    CustomerServiceComponent,
    ServiceCustomerHistoryComponent,
    CreateProgramacaoDialogComponent,
    SelectDatesComponent,
    InvoiceLogTableComponent,
    InvoiceLogComponent,
    ServiceCustomerHistoryComponent,
    BoatTuitionsComponent,
    CustomerTuitionsComponent,
    InvoiceLogComponent,
    AccessPointComponent,
    AccessPointFormComponent,
    AccessGroupComponent,
    ServiceProviderCenterComponent,
    VisitorFormComponent,
    VisitorFormPermissionComponent,
    AccessGroupComponent,
    AccessGroupFormComponent,
    AccessGroupLogComponent,
    CustomerDependentComponent,
    ProcessElementComponent,
    SplitBillComponent,
    SplittedPaymentsComponent,
    ServiceCustomerOrdersHistoryComponent,
    VisitorFormHistoricComponent,
    AccessCategoriesBatchComponent,
    SecuritiesPropertyComponent,
    SecuritiesPropertyCadLotComponent,
    SecuritiesPropertyEditComponent,
    SecuritiesPropertyHistComponent,
    DebounceClickDirective,
    SecuritiesPropertyInviteUseComponent,
    ServiceHistoryComponent,
    InvoiceLogModalComponent,
    BarcodeIconComponent,
    FranchiseGroupComponent,
    FranchiseGroupFormComponent,
    OrderHistoricComponent,
    FranchiseHistoryComponent,
    FastCustomerEditComponent,
    FastCustomerEditFormComponent,
    FranchiseEditqtymodalComponent,
    FranchiseGroupBatchComponent,
    VisitorPanelComponent,
    VisitorComponent,
    ServiceProviderFormsContainerComponent,
    GuestCenterComponent,
    ProcessControlFormComponent,
    CustomerCreditPageComponent,
    CustomerCreditHistoricComponent,
    CustomerCreditLaunchComponent,
    BoatReportComponent,
    BoatEnginesTableComponent,
    CustomerServicesDetailsComponent,
    DueExpireTuitionsComponent,
    ReportAccessControlerComponent

  ],
  imports: [
    CKEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    NgxSpinnerModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    ChartModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    ToastModule,
    FieldsetModule,
    CheckboxModule,
    RadioButtonModule,
    PanelModule,
    InputSwitchModule,
    PickListModule,
    ProgressSpinnerModule,
    FileUploadModule,
    DynamicDialogModule,
    DialogModule,
    AutoCompleteModule,
    EditorModule,
    SelectButtonModule,
    InputNumberModule,
    AccordionModule,
    InputMaskModule,
    RippleModule,
    ListboxModule,
    GoogleMapsModule,
    InputTextareaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ChipsModule,
    MovementsOperationalModule,
    AtomModule,
    MessagesModule,
    MessageModule,
    CardModule,
    MenuModule,
    ToolbarModule,
    QRCodeModule,
    SliderModule,
    DividerModule,
    ClipboardModule,
    AddressComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-br' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    {
      provide: EasyMarineHttpFinances,
      useFactory: easyMarineHttpFinancesCreator,
      deps: [HttpClient],
    },
    AuthGuard,
    SidenavBarGuard,
    StorageUtil,
    InvoiceLogService,
    MessageUtil,
    ContractUtil,
    BroadcastService,
    MyAccountService,
    SlingConfigService,
    FinancesService,
    AuthenticationService,
    UserService,
    EmailAccountService,
    SlingService,
    InvoiceService,
    DashboardService,
    CustomerService,
    BilletService,
    BoatService,
    ToastService,
    MessageService,
    BoatWashService,
    EngineTurnService,
    NavigationPlanService,
    OrderUtil,
    MarinaOrderService,
    OrderItemService,
    ImageUtil,
    ProductService,
    SlingUtil,
    InvoiceTaxLogService,
    SlingAccessoryService,
    InvoicePaidService,
    ProductCategoryService,
    FocusFieldService,
    TuitionService,
    GroupService,
    LocationService,
    ShipyardService,
    InsurerService,
    VacancyService,
    ValidatorsUtil,
    FuelService,
    ContractModelMarinaService,
    AccessoryService,
    ImportResultService,
    DialogService,
    AnticipationLotService,
    SlingLogService,
    MarinaOrderLogService,
    EasyMarineHttpApiLog,
    ShortUrlService,
    NoticeService,
    SirenAlertConfigService,
    SirenAlertService,
    PanelConfigService,
    RaspberryService,
    DocumentSignerService,
    DocumentContractedMarinaService,
    ContractDocumentService,
    MarinaContractPriceService,
    MarinaTermUseService,
    DatePipe,
    BoatAccessorieService,
    MenuItemService,
    RoleUtil,
    RoleService,
    MarinaImplantationService,
    BoatLocationService,
    InvoiceTaxDocumentService,
    NotaFiscalService,
    DocumentDefaultSignerService,
    DocumentTemplateService,
    MessageSlingDayUtil,
    BoatLogService,
    CustomerLogService,
    VariableTemplateService,
    ProductLogService,
    ContractLogService,
    OrderReferenceDateUtil,
    PivotTableService,
    InvoiceReceiptService,
    ServiceBoatService,
    ServiceComparisonService,
    MovementGroupService,
    MovementMeanService,
    MovementImageService,
    MovementExecutionService,
    MovementExecutionOperationLogService,
    MovementReportService,
    MovementManagementService,
    TimezoneUtil,
    provideNgxMask(),
    CustomReportService,
    LandExitService,
    WorkshopService,
    {
      provide: DynamicDialogConfig,
      useValue: {},
    },
    {
      provide: DynamicDialogRef,
      useValue: {},
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    PrintingService,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
