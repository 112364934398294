import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Marina, Product, SlingConfig } from 'src/app/models';
import { OrderInvoiceFilter } from 'src/app/models/dtos/order-invoice-filter';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancesService } from 'src/app/services/finances.service';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { GroupService } from 'src/app/services/group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { OrderReferenceDateUtil } from 'src/app/utils/order-reference-date.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { TuitionSummaryComponent } from '../tuitions/tuition-summary/tuition-summary.component';
import { SelectItem } from 'primeng/api';
import { ProductService } from '../../../services/product.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss'],
})
export class OrderInvoiceComponent implements OnInit {
  slingConfig: SlingConfig;
  invoice: any = { invoiceCustomers: new Array() };
  invoicesTable = new Array();
  dueDate: any;
  competenceDate: any;
  minDueDate: any;
  bilingPortifolioList: any[] = new Array();
  allSelected: boolean;
  total: number;
  filter = new OrderInvoiceFilter();
  maturityType = null;
  referenceMonth: string;
  referenceYear: string;
  totalSling: number;
  totalSingleOrder: number;
  totalTuition: number;
  totalWorkshop: number;
  globalFilter = '';
  isWorkshop: Boolean;
  orderOriginList: any[];
  monthsReference: any[] = [
    { label: 'Janeiro', value: 0 },
    { label: 'Fevereiro', value: 1 },
    { label: 'Março', value: 2 },
    { label: 'Abril', value: 3 },
    { label: 'Maio', value: 4 },
    { label: 'Junho', value: 5 },
    { label: 'Julho', value: 6 },
    { label: 'Agosto', value: 7 },
    { label: 'Setembro', value: 8 },
    { label: 'Outubro', value: 9 },
    { label: 'Novembro', value: 10 },
    { label: 'Dezembro', value: 11 },
  ];
  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };
  searchByReference = false;
  loggedMarina: Marina;
  marinaCompanies: MarinaCompany[];
  filters = false;
  groups: Array<{ label: string; value: any }>;
  groupsList: any[];
  oldGroupSelected: number[];
  productTypesList: Array<SelectItem> = [
    { label: 'Produto', value: 'Product' },
    { label: 'Serviço', value: 'Service' },
    { label: 'Serviço Periódico - Embarcação', value: 'PeriodicalService' },
    { label: 'Serviço Periódico - Cliente', value: 'PeriodicalServiceCustomer' },
  ];
  previousProductTypesSelected: string[];
  productList: Array<SelectItem>;
  productListTotal: Array<Product>;

  constructor(
    private dialog: DialogService,
    private messageService: MessageUtil,
    private spinner: NgxSpinnerService,
    private invoiceService: InvoiceService,
    private financesService: FinancesService,
    private toasty: ToastService,
    private router: Router,
    private groupService: GroupService,
    private marinaOrderService: MarinaOrderService,
    private slingConfigService: SlingConfigService,
    private orderReferenceDateUtil: OrderReferenceDateUtil,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private productService: ProductService,
    private operationalConfigService: OperationalConfigService
  ) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([this.getWorkshopConfig()]);
    this.getOrderOriginList();
    this.loggedMarina = StorageUtil.getMarina();
    this.marinaCompanies = this.loggedMarina.marinaCompanies;
    await this.findSlingConfig();
    this.getBilingPortifolioList();
    this.findGroups();
    this.clearFilter();
    this.findProducts();
    this.view();
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  getOrderOriginList(){
    this.orderOriginList = [
      { label: 'Mensalidade', value: 'TUITION' },
      { label: 'Lingada', value: 'SLING' },
      { label: 'Avulso', value: 'DETACHED' }];
      if (this.isWorkshop) {
        this.orderOriginList.push({label: 'Oficina', value: 'WORKSHOP' });
      }
    }

  clearFilter(): void {
    this.filter.referenceDate = new Date();
    this.minDueDate = new Date();
    this.minDueDate.setDate(new Date().getDate() + 1);
    this.dueDate = this.minDueDate;
    this.allSelected = false;
    this.filter.productTypes = this.setProductTypes();
  }
  filterGlobal(): void {
    this.invoicesTable = this.invoice.invoiceCustomers.filter(
      (i) =>
        FormatUtil.getNotAccents(i.customerName)
          .toUpperCase()
          .includes(
            FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
          ) ||
        i.customerName
          .toUpperCase()
          .includes(this.globalFilter.toUpperCase()) ||
        i.orders.find(
          (o) =>
            FormatUtil.getNotAccents(o.boatName)
              .toUpperCase()
              .includes(
                FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
              ) ||
            o.boatName?.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
  }

  view(): void {
    const body = new OrderInvoiceFilter();
    if (this.searchByReference) {
      if (!this.filter.referenceStart || !this.filter.referenceEnd) {
        this.messageService.generateMessage(
          'warning',
          'Atenção',
          'Preencha data de referência inicial e final.'
        );
        return;
      }
      body.orderOrigin = [];
      body.referenceDate = null;
      body.referenceStart = this.filter.referenceStart;
      body.referenceEnd = this.filter.referenceEnd;
    } else {
      if (!this.filter.referenceDate) {
        this.messageService.generateMessage(
          'warning',
          'Atenção',
          'Seleciona a data limite dos pedidos.'
        );
        return;
      }
      body.referenceStart = null;
      body.referenceEnd = null;
      body.referenceDate = this.filter.referenceDate;
      body.orderOrigin = this.filter.orderOrigin;
    }
    body.groupIds = this.filter.groupIds;
    if (this.hasFinancialMultipleByProductFilter(this.slingConfig)) {
      body.filterBillingCompany = this.filter.filterBillingCompany;
      body.billingCompany = this.filter.billingCompany;
    }
    this.spinner.show();
    this.invoiceService.viewLaunchByOrigin(body).subscribe(
      (data) => {
        this.invoice = data;
        this.invoicesTable = this.invoice.invoiceCustomers;
        this.invoicesTable.forEach((ic) =>
          this.hasQuota(ic) ? (ic.hasQuota = 'Sim') : null
        );
        this.filterGlobal();
        this.productTypesFiltration();
        this.productListFiltration();
        this.calculateTotals();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  productListFiltration(): void {
    if (this.filter?.productListIds && this.filter.productListIds.length !== 0) {
      const invoiceTable: any[] = this.invoicesTable;
      this.invoicesTable = [];
      invoiceTable.forEach((invoice) => {
        let newInvoice = {};
        let addInvoice = false;
        const orders = [];
        invoice.orders.forEach((order) => {
          let alreadyPushed = false;
          order.itens.forEach((product) => {
            if (!alreadyPushed) {
              if (this.filter.productListIds.includes(product.productId)) {
                orders.push(order);
                alreadyPushed = true;
                addInvoice = true;
              }
            }
          });
        });
        if (addInvoice) {
          let orderTotal = 0;
          orders.forEach((order) => {
            orderTotal += order.totalQuota;
          });
          newInvoice = { ...invoice, orders, total: orderTotal };
          this.invoicesTable.push(newInvoice);
        }
      });
    }
  }

  productTypesFiltration(): void {
    const invoiceTable: any[] = this.invoicesTable;
    this.invoicesTable = [];
    const productListTypes = this.validateProductTypes();
    invoiceTable.forEach((invoice) => {
      let alreadyPushed = false;
      invoice.orders.forEach((order) => {
        if (!alreadyPushed) {
          order.itens.forEach((product) => {
            if (!alreadyPushed) {
              if (productListTypes.includes(product.productType)) {
                this.invoicesTable.push(invoice);
                alreadyPushed = true;
              }
            }
          });
        }
      });
    });
  }
  private calculateTotals(): void {
    let orders = [];
    this.invoicesTable.forEach(
      (i) => (orders = orders.concat(i.orders.map((o) => o)))
    );
    this.total = this.invoicesTable.reduce((total, i) => total + i.total, 0);
    this.totalSling = orders
      .filter((o) => o.origin === 'SLING')
      .reduce((total, o) => total + o.totalQuota, 0);
    this.totalSingleOrder = orders
      .filter((o) => o.origin === 'DETACHED')
      .reduce((total, o) => total + o.totalQuota, 0);
    this.totalTuition = orders
      .filter((o) => o.origin === 'TUITION')
      .reduce((total, o) => total + o.totalQuota, 0);
    this.totalWorkshop = orders
      .filter((o) => o.origin === 'WORKSHOP')
      .reduce((total, o) => total + o.totalQuota, 0);
    }

  checkedCustomer(invoice): void {
    if (!invoice) {
      this.invoicesTable.forEach((i) => (i.selected = this.allSelected));
      this.invoicesTable.forEach((i) =>
        i.orders.forEach((o) => (o.selected = i.selected))
      );
    } else {
      this.invoicesTable
        .filter((c) => c.idCustomer === invoice.idCustomer)
        .forEach((i) => i.orders.forEach((o) => (o.selected = i.selected)));
    }
    this.allSelected =
      this.invoicesTable.find((i) => i.orders.find((o) => !o.selected)) ===
      undefined;
  }

  selectOrder(invoice): void {
    invoice.selected = invoice.orders.find((o) => o.selected) !== undefined;
    this.allSelected =
      this.invoicesTable.find((i) => i.orders.find((o) => !o.selected)) ===
      undefined;
  }

  hasOrderSelected(): boolean {
    return this.invoicesTable.find((i) => i.selected) !== undefined;
    // &&
    //   (this.maturityType === 'FIXED_DATE' ||
    //     (this.maturityType === 'CLIENT_EXPIRATION' && this.referenceMonth !== undefined && this.referenceYear !== undefined));
  }

  hasQuota(invoice): string {
    return invoice.orders.find((o) => o.quota <= 100) !== undefined
      ? 'Sim'
      : null;
  }

  getCustomersQuota(invoice): string {
    const customer = invoice.customerName;
    let customers = '';
    if (invoice.hasQuota === 'Sim') {
      for (const order of invoice.orders) {
        for (const invoiceCustomer of this.invoicesTable) {
          for (const order2 of invoiceCustomer.orders) {
            if (
              order.idOrder === order2.idOrder &&
              invoiceCustomer.customerName !== customer &&
              !customers.includes(invoiceCustomer.customerName)
            ) {
              customers =
                customers === ''
                  ? invoiceCustomer.customerName
                  : customers
                      .concat('. | .')
                      .concat(invoiceCustomer.customerName);
            }
          }
        }
      }
      return customers;
    }
    return null;
  }

  getIconOrigin(invoice): string {
    if (!invoice.orders[0]) {
      return 'error';
    }
    const origin = invoice.orders[0].origin;
    if (invoice.orders.find((o) => o.origin !== origin) !== undefined) {
      return 'MULTIPLE';
    }
    return origin;
  }

  dialogMonthlySummary(): void {
    this.dialog.open(TuitionSummaryComponent, {
      width: '50%',
      height: '90vh',
      header: 'Resumo de mensalidades',
      data: {
        origin: 'MONTHLY_INVOICE',
        monthlysSummary: this.getMonthlysSummary(),
        monthlysSummaryByGroup: this.getMonthlysSummaryByGroup(),
        monthlysSummaryByOrigin: this.getMonthlysSummaryByOrigin(),
      },
    });
  }

  private getMonthlysSummary(): any[] {
    const monthlysSummary = new Array();
    const monthlySummary = FormatUtil.groupBy(this.invoicesTable, 'dueDate');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < monthlySummary.length; index++) {
      const element = monthlySummary[index];
      const totalMonthly = monthlySummary[index].reduce(
        (total, customer) => total + customer.total,
        0
      );
      monthlysSummary.push({
        dueDate: monthlySummary[index][0].dueDate,
        customerQty: monthlySummary[index].length,
        total: totalMonthly,
      });
    }
    return monthlysSummary.sort((a, b) => a.dueDate - b.dueDate);
  }

  private getMonthlysSummaryByGroup(): any[] {
    const monthlysSummaryByGroup = new Array();
    this.invoicesTable
      .filter((ic) => !ic.groupName)
      .forEach((ic) => (ic.groupName = 'Sem grupo'));
    const monthlySummaryByGroup = FormatUtil.groupBy(
      this.invoicesTable,
      'groupName'
    );
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < monthlySummaryByGroup.length; index++) {
      const totalMonthly = monthlySummaryByGroup[index].reduce(
        (total, customer) => total + customer.total,
        0
      );
      monthlysSummaryByGroup.push({
        groupName: monthlySummaryByGroup[index][0].groupName,
        customerQty: monthlySummaryByGroup[index].length,
        total: totalMonthly,
      });
    }
    return monthlysSummaryByGroup.sort((a, b) => {
      if (a.groupName < b.groupName) {
        return -1;
      } else if (a.groupName > b.groupName) {
        return 1;
      }
      return 0;
    });
  }

  private getMonthlysSummaryByOrigin(): any[] {
    const monthlysSummaryByGroup = new Array();
    let customers = new Array();
    this.invoicesTable.forEach(
      (ic) => (customers = customers.concat(ic.orders))
    );
    const monthlySummaryByGroup = FormatUtil.groupBy(customers, 'origin');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < monthlySummaryByGroup.length; index++) {
      const totalMonthly = monthlySummaryByGroup[index].reduce(
        (total, order) => total + order.totalQuota,
        0
      );
      monthlysSummaryByGroup.push({
        origin: monthlySummaryByGroup[index][0].origin,
        orderQty: monthlySummaryByGroup[index].length,
        total: totalMonthly,
      });
    }
    return monthlysSummaryByGroup.sort((a, b) => {
      if (a.origin < b.origin) {
        return -1;
      } else if (a.origin > b.origin) {
        return 1;
      }
      return 0;
    });
  }

  showOrders(invoice): void {
    invoice.showOrders = !invoice.showOrders;
  }

  showItens(order): void {
    order.showItens = !order.showItens;
  }

  getBilingPortifolioList(): void {
    this.financesService.getAllBilingPortifolio().subscribe(
      (data) => {
        this.bilingPortifolioList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async dialogGenerate(): Promise<void> {
    const dateReference = await this.orderReferenceDateUtil.verifyRefereceDate(
      this.invoicesTable
    );
    if (!dateReference || dateReference.size < 1) return;
    this.selectDateForInvoices();
  }

  async selectDateForInvoices(): Promise<void> {
    const datesSelection =
      await this.orderReferenceDateUtil.selectDateForInvoices(this.slingConfig);
    if (datesSelection) {
      this.dueDate = datesSelection.dueDate;
      this.competenceDate = datesSelection.competenceDate;
      this.referenceMonth = datesSelection.referenceMonth;
      this.referenceYear = datesSelection.referenceYear;
      this.maturityType = datesSelection.maturityType;
      this.validatedGenerate();
    }
  }

  validatedGenerate(): void {
    Swal.fire({
      title: 'Confirmar operação?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.generate();
      },
    });
  }

  async generate(): Promise<void> {
    let invoiceCustomerDTO = {
      referenceDate: this.filter.referenceDate,
      competenceDate: this.competenceDate,
      dueDate: this.maturityType === 'FIXED_DATE' ? this.dueDate : null,
      referenceMonth: this.referenceMonth + 1,
      referenceYear: this.referenceYear,
      customerIds: new Array(),
      orderNumbers: new Array(),
    };
    console.log(this.filter.referenceDate);
    this.addOrdersId(invoiceCustomerDTO);
    this.addCustomersId(invoiceCustomerDTO);
    try {
      invoiceCustomerDTO = await this.checkQuotas(invoiceCustomerDTO);
    } catch (error) {
      const exception = error.error.data.exception;
      this.messageService.generateMessage(
        exception.type,
        exception.title,
        exception.message
      );
    }
    this.generateInvoices(invoiceCustomerDTO);
  }

  sendEmails(invoiceIds): void {
    this.invoiceService.sendEmails(invoiceIds).subscribe(
      () => {
        this.spinner.hide();

        Swal.fire({
          title: this.messageService.translateKey('SUMMARY.SUCCESS'),
          icon: 'success',
          text: this.messageService.translateKey('MSG.GENERATED-BILLETS'),
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Ir para Gestão de boletos',
          cancelButtonText: 'Fechar',
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/app/billing/billets-management']);
          } else {
            this.clearFilter();
            this.view();
          }
        });

        // this.toasty.success('Faturas geradas com sucesso.');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }

  addCustomersId(invoiceCustomerDTO): void {
    this.invoicesTable.forEach((ic) => {
      if (
        ic.orders.find((o) =>
          invoiceCustomerDTO.orderNumbers.find((n) => n === o.orderNumber)
        ) !== undefined
      ) {
        invoiceCustomerDTO.customerIds.push(ic.idCustomer);
      }
    });
  }

  addOrdersId(invoiceCustomerDTO): void {
    this.invoicesTable.forEach((ic) =>
      ic.orders
        .filter((o) => o.selected)
        .forEach((o) => invoiceCustomerDTO.orderNumbers.push(o.orderNumber))
    );
  }

  hasSelection(): boolean {
    return this.invoicesTable.find((i) => i.selected);
  }

  getCompanyIndex(company: MarinaCompany): number {
    const m = this.marinaCompanies.find((c) => c.id === company.id);
    return this.marinaCompanies.indexOf(m) + 2;
  }

  changeCompany(event: any): void {
    this.filter = event;
    let filterGroup;
    if (this.financialMultipleCompaniesService.byCustomer(this.slingConfig)) {
      // Se selecionou a marina
      if (this.filter.filterBillingCompany) {
        const filterGroupByBillingCompanyFunction =
          this.filter.billingCompany.id == undefined
            ? (g) => !g.marinaCompany
            : (g) =>
                g.marinaCompany &&
                g.marinaCompany.id === this.filter.billingCompany.id;
        filterGroup = this.groupsList
          .filter(filterGroupByBillingCompanyFunction)
          .map(this.getGroupLabel);
      } else {
        filterGroup = this.groupsList.map(this.getGroupLabel);
      }
    } else if (
      this.financialMultipleCompaniesService.byProduct(this.slingConfig)
    ) {
    } else {
      console.error(
        'Tipo de faturamento não implementado => ',
        this.slingConfig.financialMultipleCompaniesType
      );
    }
    if (filterGroup) {
      this.filter.groupIds = [-1].concat(filterGroup.map((g) => g.value));
      this.groups = [{ label: 'Sem grupo', value: -1 }].concat([
        ...filterGroup,
      ]);
    }
    this.view();
  }

  showGroupSelect(): void {
    this.oldGroupSelected = this.filter.groupIds;
  }

  hideGroupSelect(): void {
    let isChanged =
      this.oldGroupSelected.length !== this.filter.groupIds.length;
    if (!isChanged) {
      isChanged =
        this.oldGroupSelected.sort().join(',') !==
        this.filter.groupIds.sort().join(',');
    }
    if (isChanged) {
      this.view();
    }
  }

  showProductTypeListSelect(): void {
    this.previousProductTypesSelected = this.filter.productTypes;
  }

  hideProductTypeListSelect(): void {
    this.filterProductsByProductTypeSelected();
  }

  private getGroupLabel = (g: any): { label: string; value: number } => ({
    label: g.name,
    value: g.id,
  });

  filterProductsByProductTypeSelected(): void {
    const productTypesList = this.validateProductTypes();
    this.productList = [];
    this.filter.productListIds = [];
    this.productListTotal.forEach((item) => {
      if (productTypesList.includes(item.productType)) {
        this.productList.push({ label: item.name, value: item.id });
        this.filter.productListIds.push(item.id);
      }
    });
  }
  findGroups(): void {
    this.groupService.findAll().subscribe((groups) => {
      this.groupsList = groups;
      this.groups = [{ label: 'Sem grupo', value: -1 }].concat(
        groups.map((g) => ({ label: g.name, value: g.id }))
      );
      this.filter.groupIds = this.groups.map((g) => g.value);
    });
  }

  setProductTypes(): string[] {
    return this.productTypesList.map((item) => item.value);
  }

  findProducts(): void {
    this.productService.getAll().subscribe((data) => {
      this.productListTotal = data;

      this.productList = data.map((p) => ({ label: p.name, value: p.id }));
      this.filter.productListIds = [];
      this.productList.forEach((item) => {
        if (item.value !== -1) {
          this.filter.productListIds.push(item.value);
        }
      });
    });
  }

  validateProductTypes(): string[] {
    let productsType: string[] = this.filter.productTypes;
    if (productsType.length === 0) {
      productsType = this.setProductTypes();
      return productsType;
    } else {
      return productsType;
    }
  }

  validateProductList(): number[] {
    if (this.filter.productListIds.length === 0) {
      return this.productListTotal.map((p) => p.id);
    } else {
      return this.filter.productListIds;
    }
  }
  async findSlingConfig(): Promise<void> {
    return this.slingConfigService
      .getSlingConfig()
      .toPromise()
      .then((data) => {
        this.slingConfig = data[0];
      });
  }

  async checkQuotas(invoiceCustomerDTO): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.marinaOrderService.checkQuotas(invoiceCustomerDTO).subscribe(
        async (customers) => {
          if (customers.length > 0) {
            this.spinner.hide();
            Swal.fire({
              title: 'Pedidos com rateio',
              text: 'Existe(m) pedido(s) selecionado(s) com rateio de cliente em outra empresa/grupo.\nDeseja continuar?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true,
            }).then(async (result) => {
              if (result.value) {
                invoiceCustomerDTO.customerIds =
                  invoiceCustomerDTO.customerIds.concat(customers);
                resolve(invoiceCustomerDTO);
              } else {
                reject();
              }
            });
          } else {
            resolve(invoiceCustomerDTO);
          }
        },
        async (err) => {
          reject(err);
        }
      );
    });
  }

  generateInvoices(invoiceCustomerDTO): void {
    Swal.fire('Geração de Fatura', 'Suas Faturas foram para área de processamento.', 'success');

    this.invoiceService.generateInvoiceCustomers(invoiceCustomerDTO).subscribe(
      (data) => {
        this.invoice = { invoiceCustomers: new Array() };
        this.invoicesTable = [];
        this.spinner.hide();
        this.toasty.success('Faturas geradas com sucesso.');

      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
    this.clearFilter();
    this.view();
  }

  hasFinancialMultipleByProductFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(
      slingConfig
    );
  }

  hasFinancialMultipleByCustomerFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByCustomer(
      slingConfig
    );
  }

  changeProductType(event: SelectItem) {
    const option = event.label;
  }
}
